export const sliceArrayIntoChunks = ({ array, perChunk }) => {
    return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);
};
