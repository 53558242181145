import { call, put, all, takeLatest } from "redux-saga/effects";

import {
    USER_STAKED_ITEMS_REQUEST,
    USER_STAKED_ITEMS_SUCCESS,
    USER_STAKED_ITEMS_FAIL,

    USER_IMX_ITEMS_REQUEST,
    USER_IMX_ITEMS_SUCCESS,
    USER_IMX_ITEMS_FAIL,

    USER_WAX_ITEMS_REQUEST,
    USER_WAX_ITEMS_SUCCESS,
    USER_WAX_ITEMS_FAIL,

    USER_FLOW_ITEMS_REQUEST,
    USER_FLOW_ITEMS_SUCCESS,
    USER_FLOW_ITEMS_FAIL,

    USER_DARKCOUNTRY_ITEMS_REQUEST,
    USER_DARKCOUNTRY_ITEMS_SUCCESS,
    USER_DARKCOUNTRY_ITEMS_FAIL,
    USER_APTOS_ITEMS_REQUEST,
    USER_APTOS_ITEMS_SUCCESS,
    USER_APTOS_ITEMS_FAIL,
} from "../actions/actionTypes/item.action-types";

import { Api } from "../../api";
import { handleError } from "../../helpers/error.helper";
import { fetchUserWaxItems } from "../../services/wax.service";
import { fetchUserFlowItems } from "../../services/flow.service";
import { fetchUserImxItems } from "../../services/imx.service";
import { fetchUserAptosItems } from "../../services/aptos.service";
// import { CompareArrowsOutlined } from "@mui/icons-material";
//import {getDarkCountryItemsInfo} from "../../api/drivers/darkcountryItems.driver";
function* getUserStakedItems(action) {
    const { userID, blockchain} = action.payload;
    try {
        const { data } = yield call(Api.staking.getUserStakedItems, { userID, blockchain });
        console.log(data);
        yield put({
            type: USER_STAKED_ITEMS_SUCCESS,
            payload: {data, blockchain}
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_STAKED_ITEMS_FAIL,
            payload: { error: errorMessage, blockchain}
        });
    }
}
function* getUserImxItems(action) {
    try {
        const { account } = action.payload;

        const data = yield call(fetchUserImxItems, { account });
        console.log(data);
        yield put({
            type: USER_IMX_ITEMS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_IMX_ITEMS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserWaxItems(action) {
    try {
        const { account, stakedItemsIDs, lockedItems } = action.payload;
        // console.log(action.payload);
        // console.log(account);
        // console.log(stakedItemsIDs);
        // console.log( lockedItems);
        const data = yield call(fetchUserWaxItems, { account, stakedItemsIDs, lockedItems });
        console.log(data);
        yield put({
            type: USER_WAX_ITEMS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_WAX_ITEMS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserAptosItems(action) {
    try {
        debugger;
        const { account, stakedItemsIDs, lockedItems } = action.payload;

        const data = yield call(fetchUserAptosItems, { account, stakedItemsIDs, lockedItems });
        
        yield put({
            type: USER_APTOS_ITEMS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_APTOS_ITEMS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserFlowItems(action) {
    try {
        const { address, stakedItems, lockedItems } = action.payload;

        const data = yield call(fetchUserFlowItems, { address, stakedItems, lockedItems });

        yield put({
            type: USER_FLOW_ITEMS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_FLOW_ITEMS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserDarkCountryItems(action) {
    try {
        const { 
            //account, 
            stakedItemsIDs  //,
             //lockedItems 
            } = action.payload;
        console.log(action.payload);
        //console.log(account);
        console.log(stakedItemsIDs);
        //console.log( lockedItems);
        const {data} = yield call(Api.darkcountryItem.getDarkCountryItemsInfo, {});
        
        yield put({
            type: USER_DARKCOUNTRY_ITEMS_SUCCESS,
            payload: data.items
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_DARKCOUNTRY_ITEMS_FAIL,
            payload: { error: errorMessage }
        });
    }
}



function* getUserStakedItemsSaga() {
    yield takeLatest(USER_STAKED_ITEMS_REQUEST, getUserStakedItems);
}

function* getUserImxItemsSaga() {
    yield takeLatest(USER_IMX_ITEMS_REQUEST, getUserImxItems);
}

function* getUserWaxItemsSaga() {
    yield takeLatest(USER_WAX_ITEMS_REQUEST, getUserWaxItems);
}

function* getUserAptosItemsSaga() {
    yield takeLatest(USER_APTOS_ITEMS_REQUEST, getUserAptosItems);
}

function* getUserFlowItemsSaga() {
    yield takeLatest(USER_FLOW_ITEMS_REQUEST, getUserFlowItems);
}
function* getUserDarkCountryItemsSaga() {
    yield takeLatest(USER_DARKCOUNTRY_ITEMS_REQUEST, getUserDarkCountryItems);
}

export function* itemsSagas() {
    yield all([
        call(getUserStakedItemsSaga),
        call(getUserImxItemsSaga),
        call(getUserWaxItemsSaga),
        call(getUserAptosItemsSaga),
        call(getUserFlowItemsSaga),
        call(getUserDarkCountryItemsSaga),
    ])
}
