import {
    REMOVE_ACTIVE_REFORGE_CONTRACT,
    SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER,
    REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND,
    ADD_ACTIVE_REFORGE_CONTRACT
} from "../actionTypes/reforgeContract.action-types";

export const setActiveReforgeContractsForUser = (reforgeContracts) => {
    return {
        type: SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER,
        payload: reforgeContracts
    }
};

export const removeActiveReforgeContract = (reforgeContractID) => {
    return {
        type: REMOVE_ACTIVE_REFORGE_CONTRACT,
        payload: reforgeContractID
    }
};

export const removeActiveReforgeContractsByLand = (landID) => {
    return {
        type: REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND,
        payload: landID
    }
};

export const addActiveReforgeContract = (contract) => {
    return {
        type: ADD_ACTIVE_REFORGE_CONTRACT,
        payload: contract
    }
};
