import {
    OPEN_SIGN_IN_MODAL,
    OPEN_SIGN_UP_MODAL,
    OPEN_CONFIRM_EMAIL_MODAL,
    OPEN_NO_ACTIVE_LAND_MODAL,
    OPEN_ENTER_OLD_PASSWORD_MODAL,
    INFLUENCE_DEFLATION_RATE_MODAL,
    OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL,
    OPEN_TWO_FACTOR_AUTHENTICATION_MODAL,
    TOURNAMENT_PROFILE_MODAL,
    OPEN_COMBINE_ACCOUNTS_MODAL,
    OPEN_CHOOSE_FACTION_ALERT_MODAL
} from "../actions/actionTypes/modal.action-types";

const initialState = {
    openSignInModal: false,
    openSignUpModal: false,
    openConfirmEmailModal: false,
    openSetUpEmailAndPasswordModal: false,
    openEnterOldPasswordModal: false,
    landModalOpened: false,
    influenceDeflationRateModalVisible: false,
    openTwoFactorAuthenticationModal: false,
    openChooseFactionAlertModal: false,
    tournamentProfileModalVisible: false,
    combineAccountsModalVisible: false,
};

export default function modal(state = initialState, action) {
    switch (action.type) {
        case OPEN_SIGN_IN_MODAL:
            return {
                ...state,
                openSignInModal: action.payload
            };

        case OPEN_SIGN_UP_MODAL:
            return {
                ...state,
                openSignUpModal: action.payload
            };

        case OPEN_CONFIRM_EMAIL_MODAL:
            return {
                ...state,
                openConfirmEmailModal: action.payload
            };

        case OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL:
            return {
                ...state,
                openSetUpEmailAndPasswordModal: action.payload
            };

        case OPEN_NO_ACTIVE_LAND_MODAL:
            return {
                ...state,
                openNoActiveLandModal: action.payload
            };

        case OPEN_ENTER_OLD_PASSWORD_MODAL:
            return {
                ...state,
                openEnterOldPasswordModal: action.payload,
                landModalOpened: action.payload
            };

        case INFLUENCE_DEFLATION_RATE_MODAL:
            return {
                ...state,
                influenceDeflationRateModalVisible: action.payload,
            };

        case OPEN_TWO_FACTOR_AUTHENTICATION_MODAL:
            return {
                ...state,
                openTwoFactorAuthenticationModal: action.payload,
            };

        case TOURNAMENT_PROFILE_MODAL:
            return {
                ...state,
                tournamentProfileModalVisible: action.payload,
            };

        case OPEN_COMBINE_ACCOUNTS_MODAL:
            return {
                ...state,
                combineAccountsModalVisible: action.payload,
            };

        case OPEN_CHOOSE_FACTION_ALERT_MODAL:
            return {
                ...state,
                openChooseFactionAlertModal: action.payload,
            };

        default:
            return state;
    }
};
