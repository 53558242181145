export const USER_STAKED_ITEMS_REQUEST = 'USER_STAKED_ITEMS_REQUEST';
export const USER_STAKED_ITEMS_SUCCESS = 'USER_STAKED_ITEMS_SUCCESS';
export const USER_STAKED_ITEMS_FAIL = 'USER_STAKED_ITEMS_FAIL';

export const USER_IMX_ITEMS_REQUEST = 'USER_IMX_ITEMS_REQUEST';
export const USER_IMX_ITEMS_SUCCESS = 'USER_IMX_ITEMS_SUCCESS';
export const USER_IMX_ITEMS_FAIL = 'USER_IMX_ITEMS_FAIL';

export const USER_WAX_ITEMS_REQUEST = 'USER_WAX_ITEMS_REQUEST';
export const USER_WAX_ITEMS_SUCCESS = 'USER_WAX_ITEMS_SUCCESS';
export const USER_WAX_ITEMS_FAIL = 'USER_WAX_ITEMS_FAIL';
export const USER_WAX_UPDATE_SOUL_CARD = 'USER_WAX_UPDATE_SOUL_CARD';

export const USER_APTOS_ITEMS_REQUEST = 'USER_APTOS_ITEMS_REQUEST';
export const USER_APTOS_ITEMS_SUCCESS = 'USER_APTOS_ITEMS_SUCCESS';
export const USER_APTOS_ITEMS_FAIL = 'USER_APTOS_ITEMS_FAIL';

export const USER_FLOW_ITEMS_REQUEST = 'USER_FLOW_ITEMS_REQUEST';
export const USER_FLOW_ITEMS_SUCCESS = 'USER_FLOW_ITEMS_SUCCESS';
export const USER_FLOW_ITEMS_FAIL = 'USER_FLOW_ITEMS_FAIL';

export const ITEMS_IN_QUESTS_PARTIES_SUCCESS = 'ITEMS_IN_QUESTS_PARTIES_SUCCESS';
export const ITEMS_IN_QUESTS_PARTIES_FAIL = 'ITEMS_IN_QUESTS_PARTIES_FAIL';

export const ITEMS_IN_REFORGE_CONTRACTS_SUCCESS = 'ITEMS_IN_REFORGE_CONTRACTS_SUCCESS';
export const ITEMS_IN_REFORGE_CONTRACTS_FAIL = 'ITEMS_IN_REFORGE_CONTRACTS_FAIL';

export const USER_DARKCOUNTRY_ITEMS_REQUEST ='USER_DARKCOUNTRY_ITEMS_REQUEST';
export const USER_DARKCOUNTRY_ITEMS_SUCCESS ='USER_DARKCOUNTRY_ITEMS_SUCCESS';
export const USER_DARKCOUNTRY_ITEMS_FAIL ='USER_DARKCOUNTRY_ITEMS_FAIL';