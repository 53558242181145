import axios from "axios";
import { mapImxItemsResponse } from "../helpers/response.helper";

function recursiveRequest(url, dataArray = [], cursor='') {
    return new Promise((resolve, reject) => {
      axios.get(url+`&cursor=${cursor}`)
        .then(({data:{cursor, remaining, result}}) => {

          const updatedDataArray = [...dataArray,...result];
          
          if (remaining == 0) {
            resolve(updatedDataArray);
          } else {
            resolve(recursiveRequest(url,updatedDataArray,cursor));
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  
export const fetchUserImxItems = async ( {account} ) => {
    const baseImxUrl = 'https://api.x.immutable.com/v1'; 
    const collection = '0x2765A8eBB20739632bBdB8349501989cb5a0f18A';
    const url = `${baseImxUrl}/assets?user=${account}&collection=${collection}&status=imx`
    
    try {
        let assets = await recursiveRequest(url);
        return mapImxItemsResponse(assets);
    } catch (error) {
        console.log('[ImxService]', 'Get Imx cards error:', {error});
        return [];
    }

};

export const isWalletSetUpInImx = async (wallet) => {
  let result = false;
  try {
    const baseImxUrl = "https://api.x.immutable.com/v1";
    const res = await axios.get(`${baseImxUrl}/users/${wallet}`);
    result = !!res?.data?.accounts?.length;
  } catch (error) {
    console.log(error);
  } finally {
    return result;
  }
};
