export const ACTIVE_QUESTS_REQUEST = 'ACTIVE_QUESTS_REQUEST';
export const ACTIVE_QUESTS_SUCCESS = 'ACTIVE_QUESTS_SUCCESS';
export const ACTIVE_QUESTS_FAIL = 'ACTIVE_QUESTS_FAIL';

export const USER_JOINED_QUESTS_REQUEST = 'USER_JOINED_QUESTS_REQUEST';
export const USER_JOINED_QUESTS_SUCCESS = 'USER_JOINED_QUESTS_SUCCESS';
export const USER_JOINED_QUESTS_FAIL = 'USER_JOINED_QUESTS_FAIL';

export const USER_QUESTS_PARTIES_REQUEST = 'USER_QUESTS_PARTIES_REQUEST';
export const USER_QUESTS_PARTIES_SUCCESS = 'USER_QUESTS_PARTIES_SUCCESS';
export const USER_QUESTS_PARTIES_FAIL = 'USER_QUESTS_PARTIES_FAIL';

export const SET_ACTIVE_QUESTS_FOR_USER = 'SET_ACTIVE_QUESTS_FOR_USER';

export const REMOVE_ACTIVE_QUEST = 'REMOVE_ACTIVE_QUEST';
export const UPDATE_ACTIVE_QUESTS = 'UPDATE_ACTIVE_QUESTS';


