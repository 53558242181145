

export const DARKCOUNTRY_APTOS_CONTRACT = process.env.REACT_APP_APTOS_CHAIN === 'testnet'
    ? '0x2de6aea32fcb7ab2e33ab9a78df3b5f4ef5b718ef77475f96ed8a66f466afc28'
    : '0x3790240aa9d7400d42d99181e0f2364c84d7ef8e9639b99151132a631b11e7d4';

export const DARKCOUNTRY_APTOS_COLLECTION_HASH = process.env.REACT_APP_APTOS_CHAIN === 'testnet'
    ? '0xa2ec758855780605725b54f3761637a04d38f6d184ccabfc9ef5ee1d05bf18f0'
    : '0x71aa42b2d1bb47cad15b27ac31a6fd8c35d0927930352adc50b58e2bb645b6f1'

export const DARKCOUNTRY_APTOS_TABLE_HANDLER = process.env.REACT_APP_APTOS_CHAIN === 'testnet'
    ? '0xdda75dffc271b6a03dfe2d9c29c02fbfdff533622f9f2b4ec62243071845998e'
    : '0x124781d0f084c4ef3b99111d6fe835e034e091a37988736a16e4954d702d8ee4'

export const DARKCOUNTRY_SDM_APTOS_CONTRACT = process.env.REACT_APP_APTOS_CHAIN === 'testnet'
    ? '0xa425c664477b9dafde9a85e6e24fc948538a4586dc7db9301fb9aba75a1abda1'
    : '0xf20db06e2756e5d933b9d7295573fab0a69bc6c012d438b6c92f349e70ba4a32'

export const SDM_APTOS_DECIMAL= 10000