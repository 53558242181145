import axios from "axios";

import { to2,toLocal } from "../../helpers/api.helper";

export const tokenomic = {

   getTokenomic: () => {
        return axios.get(to2("tokenomic/info"));
    }
};
