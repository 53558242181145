export const WAX = 'WAX';
export const FLOW = 'FLOW';
export const EOS = 'EOS';
export const ETH = 'ETH';
export const DARKCOUNTRY = 'DARKCOUNTRY';
export const IMX = 'IMX';
export const APTOS = 'APTOS';
export const HANDCASH = "HANDCASH";
//auctions

export const COLLECT_WHALE = 'collectwhale';
export const KEY_SCHEMA = 'key';
export const ATOMIC_ASSETS = 'atomicassets';