import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";
import {bcs, fromHEX} from "@mysten/bcs";
import {
  DARKCOUNTRY_APTOS_CONTRACT,
  DARKCOUNTRY_APTOS_COLLECTION_HASH,
  DARKCOUNTRY_APTOS_TABLE_HANDLER,
  DARKCOUNTRY_SDM_APTOS_CONTRACT,
  SDM_APTOS_DECIMAL,
} from "../constants/aptos.constants";
import { mapAptosItemsResponse } from "../helpers/response.helper";

const aptosConfig = new AptosConfig({
  network:
    process.env.REACT_APP_APTOS_CHAIN === "testnet"
      ? Network.TESTNET
      : Network.MAINNET
});
export const aptos = new Aptos(aptosConfig);

export const stakeAptosItems = async (
  signAndSubmitTransaction,
  account,
  items
) => {
  return await signAndSubmitTransaction({
    sender: account.address,
    data: {
      function: `${DARKCOUNTRY_APTOS_CONTRACT}::staking::stake_tokens`,
      functionArguments: [items],
    },
  });
};

export const unstakeAptosItems = async (
  signAndSubmitTransaction,
  account,
  items
) => {
  return await signAndSubmitTransaction({
    sender: account.address,
    data: {
      function: `${DARKCOUNTRY_APTOS_CONTRACT}::staking::unstake_tokens`,
      functionArguments: [items],
    },
  });
};

export const registerSdmCoin = async ({
  signAndSubmitTransaction,
  account
}) => {
  console.log(account)
  return await signAndSubmitTransaction({
    sender: account.address,
    data: {
      function: `${DARKCOUNTRY_SDM_APTOS_CONTRACT}::sdm::register`,
      functionArguments: [],
    },
  });
};

export const transferSdmCoinToDarkCountry = async ({
  signAndSubmitTransaction,
  account,
  amount
}) => {
  console.log(account)
  return await signAndSubmitTransaction({
    sender: account.address,
    data: {
      function: `${DARKCOUNTRY_SDM_APTOS_CONTRACT}::sdm::transfer`,
      functionArguments: [DARKCOUNTRY_SDM_APTOS_CONTRACT,
        amount * SDM_APTOS_DECIMAL
      ],
    },
  });
};

export const fetchUserStakedAptosItems = async ({ account }) => {
  try {
    const stakedNFts = await aptos.view({
      payload: {
        function: `${DARKCOUNTRY_APTOS_CONTRACT}::staking::get_staked_tokens`,
        typeArguments: [],
        functionArguments: [account],
      },
    });
    if (!stakedNFts[0].length) return [];

    return getNftsTemplateById(stakedNFts[0]);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const fetchUserAptosItems = async ({
  account,
  stakedItemsIDs,
  lockedItems,
}) => {
  let allNfts = [];
  let offset = 0;
  const limit = 100; // Adjust this based on the API's limit
  try {
    while (true) {
      let result = await aptos.getAccountOwnedTokensFromCollectionAddress({
        accountAddress: account,
        collectionAddress: DARKCOUNTRY_APTOS_COLLECTION_HASH,
        options: {
          offset: offset,
          limit: limit,
        },
      });

      if (result.length === 0) break;

      allNfts.push(...result);
      offset += limit;
    }

    const filteredNfts = allNfts.filter(({ current_token_data }) => {
      return current_token_data?.token_properties?.Type !== "Pack";
    });

    const stakedItems = await fetchUserStakedAptosItems({ account });

    return mapAptosItemsResponse({
      items: [...filteredNfts, ...stakedItems],
      stakedItemsIDs,
      lockedItems,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getWalletAptosSdmBalance = async ({ account }) => {
  const resource = await aptos.getAccountResource({
    accountAddress: account,
    resourceType: `0x1::coin::CoinStore<${DARKCOUNTRY_SDM_APTOS_CONTRACT}::sdm::SDM>`,
  });

  return `${Number(resource.coin.value/ SDM_APTOS_DECIMAL)} SDM`;
};

export const isAccountRegisteredForSdmCoin = async (accountAddress) => {
  try {
      const resourceType = `0x1::coin::CoinStore<${DARKCOUNTRY_SDM_APTOS_CONTRACT}::sdm::SDM>`;
      const resources = await aptos.getAccountResources({accountAddress});
      return resources.some(resource => resource.type === resourceType);
  } catch (error) {
      console.error('Error checking account registration for coin:', error);
      return false;
  }
};

const getNftsTemplateById = async (ids) => {
  const templateCache = {};
  const templates = await Promise.all(
    ids.map(async ({ template_id, name }) => {
      if (templateCache[template_id]) {
        return templateCache[template_id];
      }

      const templatesTableItem = {
        key_type: "u64",
        value_type: `${DARKCOUNTRY_APTOS_CONTRACT}::templates::Template`,
        key: `${template_id}`,
      };

      const template = await aptos.getTableItem({
        handle: DARKCOUNTRY_APTOS_TABLE_HANDLER,
        data: templatesTableItem,
      });

      const { uri, id, description, property_names, property_values_bytes } =
        template;

      const properties = {};
      property_names.forEach((name, index) => {
        properties[name.toLowerCase()] = aptosHexToString(
          property_values_bytes[index]
        );
      });

      const cachedTemplate = {
        uri,
        template_id: id,
        description,
        current_token_data: {
          token_name: name,
          token_properties: properties,
        },
      };

      templateCache[template_id] = cachedTemplate;
      return cachedTemplate;
    })
  );

  return templates;
};


const aptosHexToString = (hex) => {
  return bcs.string().parse(fromHEX(hex))
};

