import { store } from "../";

import {
    USER_STAKED_ITEMS_REQUEST,
    USER_WAX_ITEMS_REQUEST,
    USER_FLOW_ITEMS_REQUEST,
    USER_DARKCOUNTRY_ITEMS_REQUEST,
    USER_IMX_ITEMS_REQUEST,
    USER_APTOS_ITEMS_REQUEST,
} from "../redux/actions/actionTypes/item.action-types";

export const fetchStakedUserItems = ({ userID, blockchain }) => {
    store.dispatch({
        type: USER_STAKED_ITEMS_REQUEST,
        payload: { userID, blockchain }
    });
};

export const fetchImxUserItems = ( ethAccount ) => {
    store.dispatch({
        type: USER_IMX_ITEMS_REQUEST,
        payload: {
            account: ethAccount,
        }
    });
};

export const fetchWaxUserItems = ({ waxAccount, stakedItems, lockedItems}) => {
    store.dispatch({
        type: USER_WAX_ITEMS_REQUEST,
        payload: {
            account: waxAccount,
            stakedItemsIDs: stakedItems
                ? stakedItems.stakedAssets.map(({ id }) => id)
                : [],
            lockedItems: lockedItems ? lockedItems : [],
        }
    });
};

export const fetchAptosUserItems = ({ aptosAccount, stakedItems, lockedItems}) => {
    store.dispatch({
        type: USER_APTOS_ITEMS_REQUEST,
        payload: {
            account: aptosAccount,
            stakedItemsIDs: stakedItems
                ? stakedItems.stakedAssets.map(({ id }) => id)
                : [],
            lockedItems: lockedItems ? lockedItems : [],
        }
    });
};

export const fetchFlowUserItems = ({ flowAccount, stakedItems, lockedItems }) => {
   
    store.dispatch({
        type: USER_FLOW_ITEMS_REQUEST,
        payload: {
            address: flowAccount,
            stakedItems: stakedItems
                ? stakedItems.stakedAssets.map(({ id }) => id)
                : [],
            lockedItems: lockedItems ? lockedItems : []
        }
    });
};

export const fetchDarkCountryUserItems = () => {
    store.dispatch({
        type: USER_DARKCOUNTRY_ITEMS_REQUEST,
        payload: { }
    });
};
