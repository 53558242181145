import * as fcl from "@blocto/fcl"
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";
// import { Wombat } from "ual-wombat";
import { Wombat } from 'wombat-ual'

import {
    BLOCTO_ENDPOINT,
    BLOCTO_PROJECT_ID,
    FLOW_ACCESS_NODE_ENDPOINT
} from "../constants/flow.constants";
import {
    WAX_CHAIN_ID,
    WAX_RPC_ENDPOINTS_HOST,
    WAX_RPC_ENDPOINTS_PROTOCOL
} from "../constants/wax.constants";

fcl
  .config({
    "accessNode.api": FLOW_ACCESS_NODE_ENDPOINT,
    "discovery.wallet": BLOCTO_ENDPOINT,
    "flow.network":  process.env.REACT_APP_FLOW_CHAIN,
    "discovery.wallet.method": "HTTP/POST",
    "app.detail.id": BLOCTO_PROJECT_ID
  })


export const waxChain = {
    chainId: WAX_CHAIN_ID,
    rpcEndpoints: [{
        protocol: WAX_RPC_ENDPOINTS_PROTOCOL,
        host: WAX_RPC_ENDPOINTS_HOST,
        port: ''
    }]
};

const wcw = new Wax([waxChain], { appName: 'DarkCountry' });
const wombat = new Wombat([waxChain], { appName: 'DarkCountry' });
const anchor = new Anchor([waxChain], { appName: 'DarkCountry' });

export const waxAuthenticators = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? [anchor]
    : [anchor, wcw, wombat];
