import axios from "axios";

import { to2,toLocal } from "../../helpers/api.helper";

export const guild = {
    getGuilds: () => {
        return axios.get(to2("guild"));
    },

    getLandsGuilds: ({landsIDs}) => {
       
        return axios.post(to2("guild/lands"),{landsIDs:landsIDs});
    },

    createGuild: ({ name, accessibility, landID }) => {
        
        return axios.post(to2("guild"), { name, accessibility, landID });
    },

    updateGuild: ({ guildID, landID, accessibility }) => {
        return axios.patch(to2("guild"), { guildID, landID, accessibility });
    },

    deleteGuild: ({ guildID, landID }) => {
        return axios.delete(to2(`guild?guildID=${guildID}&landID=${landID}`));
    },

    applyToGuild: ({ guildID, landID }) => {
        return axios.post(to2("guild/apply"), { guildID, landID });
    },

    revokeApplication: ({ guildID, landID }) => {
        return axios.patch(to2("guild/apply"), { guildID, landID });
    },

    approveApplication: ({ guildID, vacancyID }) => {
        return axios.patch(to2("guild/applicant/approve"), { guildID,vacancyID });
    },

    rejectApplication: ({ guildID, vacancyID }) => {
        return axios.patch(to2("guild/applicant/reject"), { guildID,vacancyID });
    },

    upgradeMemberRank: ({ guildID, userID }) => {
        return axios.patch(to2("guild/member/upgrade"), { guildID, userID });
    },

    downgradeMemberRank: ({ guildID,userID }) => {
        return axios.patch(to2("guild/member/downgrade"), { guildID,userID });
    },
    downgradeMemberRank: ({ guildID,userID }) => {
        return axios.patch(to2("guild/member/downgrade"), { guildID,userID });
    },

    removeMember: ({ guildID, userID}) => {
        return axios.post(to2("guild/member/remove"), { guildID,userID});
    },

    changeMemberRank: ({  guildID, memberID, rank }) => {
        return axios.post(to2("guild/member/update/rank"), {  guildID, memberID, rank });
    },
    changeMemberSalary: ({  guildID, memberID, salary }) => {
        return axios.post(to2("guild/member/update/salary"), {  guildID:guildID, memberID:memberID, salary:salary});
    },

   

    updateSalaries: ({ guildID, salaries }) => {
        return axios.post(to2("guild/salaries"), { guildID, salaries });
    },
    createVacancy: ({ guildID, rank, salary }) => {
        return axios.post(to2("guild/vacancy"), { guildID, rank,salary });
    },

    updateVacancy: ({ guildID, vacancyID, salary }) => {
        return axios.post(to2("guild/vacancy/update"), { guildID, vacancyID,salary });
    },
    deleteVacancy: ({ guildID, vacancyID}) => {
        return axios.post(to2("guild/vacancy/remove"), { guildID, vacancyID});
    },
    
    inviteRecruit:({landID, userID, rank, salary}) =>{
        return axios.post(to2("guild/recruit/invite"),{landID:landID, userID:userID, rank:rank, salary:salary});
    },
    getRecruits:({page, limit, sortObj, foundName}) =>{
        console.log({page, limit, sortObj, foundName});
        return axios.post(to2("guild/recruits"),{page:page, limit:limit, sortObj:sortObj, foundName:foundName});
    },
};
