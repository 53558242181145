import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
    ACTIVE_REFORGE_CONTRACTS_REQUEST,
    ACTIVE_REFORGE_CONTRACTS_SUCCESS,
    ACTIVE_REFORGE_CONTRACTS_FAIL,
    USER_JOINED_REFORGE_CONTRACTS_REQUEST,
    USER_JOINED_REFORGE_CONTRACTS_SUCCESS,
    USER_JOINED_REFORGE_CONTRACTS_FAIL
} from "../actions/actionTypes/reforgeContract.action-types";
import {
    ITEMS_IN_REFORGE_CONTRACTS_SUCCESS,
    ITEMS_IN_REFORGE_CONTRACTS_FAIL,
} from "../actions/actionTypes/item.action-types";
import { handleError } from "../../helpers/error.helper";

function* getActiveReforgeContracts() {
    try {
        const { data } = yield call(Api.reforgeContract.getAvailableReforgeContracts);

        yield put({
            type: ACTIVE_REFORGE_CONTRACTS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: ACTIVE_REFORGE_CONTRACTS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserJoinedReforgeContracts() {
    try {
        const { data } = yield call(Api.reforgeContract.getUserJoinedReforgeContracts);

        try {
            const items = data.map(({ heroAssetID }) => heroAssetID.toString());

            yield put({
                type: ITEMS_IN_REFORGE_CONTRACTS_SUCCESS,
                payload: items
            });
        } catch (error) {
            const errorMessage = handleError({ error });

            yield put({
                type: ITEMS_IN_REFORGE_CONTRACTS_FAIL,
                payload: { error: errorMessage }
            });
        }

        yield put({
            type: USER_JOINED_REFORGE_CONTRACTS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_JOINED_REFORGE_CONTRACTS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getActiveReforgeContractsSaga() {
    yield takeLatest(ACTIVE_REFORGE_CONTRACTS_REQUEST, getActiveReforgeContracts);
}

function* getUserJoinedReforgeContractsSaga() {
    yield takeLatest(USER_JOINED_REFORGE_CONTRACTS_REQUEST, getUserJoinedReforgeContracts);
}

export function* reforgeContractSagas() {
    yield all([
        call(getActiveReforgeContractsSaga),
        call(getUserJoinedReforgeContractsSaga),
    ]);
}
