export const ACTIVE_REFORGE_CONTRACTS_REQUEST = 'ACTIVE_REFORGE_CONTRACTS_REQUEST';
export const ACTIVE_REFORGE_CONTRACTS_SUCCESS = 'ACTIVE_REFORGE_CONTRACTS_SUCCESS';
export const ACTIVE_REFORGE_CONTRACTS_FAIL = 'ACTIVE_REFORGE_CONTRACTS_FAIL';
export const ADD_ACTIVE_REFORGE_CONTRACT = 'ADD_ACTIVE_REFORGE_CONTRACT';

export const SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER = 'SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER';

export const USER_JOINED_REFORGE_CONTRACTS_REQUEST = 'USER_JOINED_REFORGE_CONTRACTS_REQUEST';
export const USER_JOINED_REFORGE_CONTRACTS_SUCCESS = 'USER_JOINED_REFORGE_CONTRACTS_SUCCESS';
export const USER_JOINED_REFORGE_CONTRACTS_FAIL = 'USER_JOINED_REFORGE_CONTRACTS_FAIL';

export const REMOVE_ACTIVE_REFORGE_CONTRACT = 'REMOVE_ACTIVE_REFORGE_CONTRACT';
export const REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND = 'REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND';
