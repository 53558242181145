import axios from "axios";

import { to2,toLocal } from "../../helpers/api.helper";

export const tournament = {
    createTournament: ({ landID, name, prizePool }) => {
        return axios.post(to2('tournaments'), { landID, name, prizePool });
    },

    buyTicketBySdm: ({ blockchain }) => {
        return axios.post(to2('tournaments/ticket'), { blockchain });
    },
}
