import axios from "axios";

import { to, toLocal } from "../../helpers/api.helper";

export const darkcountryItem = {
    getDarkCountryItemsInfo: () => {
        return axios.get(to(`darkcountry/asset-info`));
    },

   
};
