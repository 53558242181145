import * as fcl from "@blocto/fcl";

export async function tx(mods = {}) {
  const txId = await fcl.mutate(mods);
  console.info(
    `%cTX[${txId}]: ${fvsTx(txId)}`,
    "color:purple;font-weight:bold;font-family:monospace;"
  );

  await fcl.tx(txId).onceSealed();

  console.info(
    `%cTX[${txId}]: ${fvsTx(txId)}`,
    "color:green;font-weight:bold;font-family:monospace;"
  );

  return txId;
}

function fvsTx(txId) {
  return `https://flow-view-source.com/${process.env.REACT_APP_FLOW_CHAIN}/tx/${txId}`;
}
