export const DARKCOUNTRY_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x55a36fa8323e39bf'
    : '0xc8c340cebd11f690';

export const DARKCOUNTRY_MARKET_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x55a36fa8323e39bf'
    : '0xc8c340cebd11f690';

export const DARKCOUNTRY_STAKING_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x55a36fa8323e39bf'
    : '0xc8c340cebd11f690';

export const DARKCOUNTRY_SDM_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x55a36fa8323e39bf'
    : '0xc8c340cebd11f690';

export const NON_FUNGIBLE_TOKEN_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x631e88ae7f1d7c20'
    : '0x1d7e57aa55817448';

export const FUNGIBLE_TOKEN_ADDRESS = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? '0x9a0766d93b6608b7'
    : '0xf233dcee88fe0abe';

export const FLOW_ACCESS_NODE_ENDPOINT = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? 'https://rest-testnet.onflow.org'
    : 'https://rest-mainnet.onflow.org';

export const BLOCTO_ENDPOINT = process.env.REACT_APP_FLOW_CHAIN === 'testnet'
    ? 'https://wallet-v2-dev.blocto.app/api/flow/authn'
    : 'https://wallet-v2.blocto.app/api/flow/authn';

export const BLOCTO_PROJECT_ID = "3d5acaff-58c6-4991-a2e1-0e61b9a4da0d";

export const RANCHO_PACK_TEMPLATE_ID = 2;
export const MAYOR_PACK_TEMPLATE_ID = 3;
export const GOVERNORS_PACK_TEMPLATE_ID = 4;
export const BONUS_PACK_TEMPLATE_ID = 5;

export const PACKS_TEMPLATES = [
    RANCHO_PACK_TEMPLATE_ID,
    MAYOR_PACK_TEMPLATE_ID,
    GOVERNORS_PACK_TEMPLATE_ID,
    BONUS_PACK_TEMPLATE_ID
];
