import { call, put, all, takeLatest } from "redux-saga/effects";

import {
    ACTIVE_QUESTS_REQUEST,
    ACTIVE_QUESTS_SUCCESS,
    ACTIVE_QUESTS_FAIL,
    USER_JOINED_QUESTS_REQUEST,
    USER_JOINED_QUESTS_SUCCESS,
    USER_JOINED_QUESTS_FAIL,
    USER_QUESTS_PARTIES_REQUEST,
    USER_QUESTS_PARTIES_SUCCESS,
    USER_QUESTS_PARTIES_FAIL
} from "../actions/actionTypes/quest.action-types";
import {
    ITEMS_IN_QUESTS_PARTIES_SUCCESS,
    ITEMS_IN_QUESTS_PARTIES_FAIL
} from "../actions/actionTypes/item.action-types";

import { Api } from "../../api";
import { handleError } from "../../helpers/error.helper";

function* getActiveQuests(req,pa =1,l=20,srt="sdm",bl=null,r=null) {
    console.log(req);
    try {
        const { data } = yield call(Api.quest.getAvailableQuests,pa,l,srt,bl,r);
        console.log(data);
        yield put({
            type: ACTIVE_QUESTS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: ACTIVE_QUESTS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserJoinedQuests() {
    try {
        const { data } = yield call(Api.quest.getUserJoinedQuests);

        yield put({
            type: USER_JOINED_QUESTS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_JOINED_QUESTS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserQuestsParties() {
    try {
        const { data } = yield call(Api.questParty.getUserQuestsParties);

        try {
            const items = data.map(({ cards, heroAssetID }) => [...cards.map(card => card.toString()), heroAssetID.toString()])
                .flat(1);

            yield put({
                type: ITEMS_IN_QUESTS_PARTIES_SUCCESS,
                payload: items
            });
        } catch (error) {
            const errorMessage = handleError({ error });

            yield put({
                type: ITEMS_IN_QUESTS_PARTIES_FAIL,
                payload: { error: errorMessage }
            });
        }

        yield put({
            type: USER_QUESTS_PARTIES_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_QUESTS_PARTIES_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getActiveQuestsSaga() {
    yield takeLatest(ACTIVE_QUESTS_REQUEST, getActiveQuests);
}

function* getUserJoinedQuestsSaga() {
    yield takeLatest(USER_JOINED_QUESTS_REQUEST, getUserJoinedQuests);
}

function* getUserQuestsPartiesSaga() {
    yield takeLatest(USER_QUESTS_PARTIES_REQUEST, getUserQuestsParties);
}

export function* questSagas() {
    yield all([
        call(getActiveQuestsSaga),
        call(getUserJoinedQuestsSaga),
        call(getUserQuestsPartiesSaga),
    ])
}
