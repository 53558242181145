import axios from "axios";

import { store } from "../";

import { Api } from "../api";
import {
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_USER_ID
} from "../constants/localStorage.constants";
import { setAuthTokensToStorage } from "../helpers/auth.helper";
import { getFromLocalStorageWithExpiry } from "../helpers/storage.helper";

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
    (config) => {
        const accessToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_ACCESS_TOKEN });

        if (accessToken)
            config.headers['Authorization'] = accessToken;

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const refreshToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_REFRESH_TOKEN });

        const userIDFromStorage = localStorage.getItem(LOCAL_STORAGE_USER_ID);
        const { auth: { auth: { id: userIDFromState } } } = store.getState();

        const userID = userIDFromStorage ? userIDFromStorage : userIDFromState;

        if (refreshToken && userID
            && (error.response && error.response.status === 401)
            && !originalRequest._retry
        ) {
            originalRequest._retry = true;

            const { status, data } = await Api.auth.refreshToken({ userID, refreshToken });

            if (status === 200) {
                setAuthTokensToStorage({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                })

                originalRequest.headers['Authorization'] = data.accessToken;
                originalRequest.data = originalRequest.data ? JSON.parse(originalRequest.data) : {}
            }

            return axios(originalRequest);
        }

        return Promise.reject(error);
    }
);
