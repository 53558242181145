import axios from "axios";

import { to, to2,  to3, toLocal } from "../../helpers/api.helper";

export const user = {
    getProfile: ({ userID }) => {
        return axios.get(to(`user/profile?id=${userID}`));
    },

    updateUsername: ({ username }) => {
        return axios.patch(to('user/username'), { username });
    },

    setPassword: ({ password }) => {
        return axios.post(to('user/password/set'), { password });
    },

    changePassword: ({ oldPassword, newPassword }) => {
        return axios.patch(to('user/password/change'), { oldPassword, newPassword });
    },

    resetPassword: ({ id, token, password }) => {
        return axios.post(to('user/password/reset'), { id, token, password });
    },

    resetPasswordInit: ({ email }) => {
        return axios.post(to('user/password/reset/init'), { email });
    },

    confirmEmailInit: ({ email }) => {
        return axios.post(to("user/email/confirm/init"), { email });
    },

    confirmEmail: ({ id, token }) => {
        return axios.post(to("user/email/confirm"), { id, token });
    },

    updateEmailInit: ({ emailToUpdate, password }) => {
        return axios.post(to('user/email/init'), { emailToUpdate, password });
    },

    updateEmail: ({ id, token }) => {
        return axios.patch(to('user/email'), { id, token });
    },

    changeEmail: ({ oldEmail, newEmail }) => {
        return axios.post(to('user/email'), { oldEmail, newEmail });
    },

    linkWaxAccount: ({ account, wallet }) => {
        return axios.patch(to("user/link/wax"), { account, wallet });
    },

    linkAptosAccount: ({ account }) => {
        return axios.patch(to3("user/link/aptos"), { account });
    },

    linkMetamaskAccount: ({ account }) => {
        return axios.patch(to3("user/link/eth-imx"), { account });
    },

    linkFlowAccount: ({ account }) => {
        return axios.patch(to("user/link/flow"), { account });
    },

    unlinkAccount: ({ blockchain }) => {
        return axios.patch(to("user/unlink"), { blockchain });
    },

    checkIfWaxAccountExist: ({ waxAccount }) => {
        return axios.get(to(`user/wax/check?waxAccount=${waxAccount}`));
    },

    checkIfFlowAccountExist: ({ flowAccount }) => {
        return axios.get(to(`user/flow/check?flowAccount=${flowAccount}`));
    },
    checkIfAptosAccountExist: ({ aptosAccount }) => {
        return axios.get(to3(`user/aptos/check?aptosAccount=${aptosAccount}`));
    },
    
    // checkIfMetamaskAccountExist: ({ metamaskAccount }) => {
    //     return axios.get(to(`user/eth/check?metamaskAccount=${metamaskAccount}`));
    // },

    combineAccountsInit: ({ email, waxAccount, flowAccount }) => {
        return axios.post(to("user/account/combine/init"), { email, waxAccount, flowAccount });
    },

    combineAccounts: ({ id, token }) => {
        return axios.post(to("user/account/combine"), { id, token });
    },

    getUserSubs: ({ subscriptionType }) => {
        return axios.post(to2("user/subscriptions"), { subscriptionType });  //to2
    },

    createUserSub: (body) => {
        return axios.post(to2("user/subscription/create"), body);   //to2
    },

    //Can get a status about the possibility of using auto-repeat field of quests
    getUserSubArqStatus: () => {
        return axios.get(to2("user/subscription/arq"));   //to2
    },

    finduser: (userName) =>{
        return axios.post(to("user/find"),{username:userName});
    }
};
