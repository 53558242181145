import axios from "axios";

import { to,toLocal } from "../../helpers/api.helper";

export const quest = {
    createQuest: ({ name, rarity, sdmReward, landID }) => {
        return axios.post(to("quest"), {
            name, rarity, sdmReward, landID
        });
    },

    updateQuest: ({ questID, autoRepeat }) => {
        return axios.patch(to("quest"), {
            questID, autoRepeat
        });
    },

    deleteQuest: ({ questID }) => {
        return axios.delete(to(`quest?questID=${questID}`));
    },

    joinQuest: ({ questID, questPartyID }) => {
        return axios.post(to("quest/join"), {
            questID, questPartyID
        });
    },

    getAvailableQuests: (page =1,limit=20,sort="sdm",blockchain=null,rarity=null, activeQuestStatus=null,foundName=null) => {
        var params = "";
        if(page < 1){
            page = 1;
        }
        if(limit < 1){
            limit = 1;
        }
        params+="page="+page;
        params+="&&limit="+limit;
        if(sort && sort!==""){
            params+="&&sort="+sort;
        }
        if(blockchain && blockchain!==""){
            params+="&&bl="+blockchain;
        }
        if(rarity && rarity!=="")
        {
        params+="&&rarity="+rarity;}
        if(activeQuestStatus && activeQuestStatus!==""){
           
            params+="&&st="+activeQuestStatus;
        }
        if(foundName)
        {

            params+="&&fn='"+foundName+"'";
        }
        console.log(params);
        return axios.get(to("quest?"+params));
    },

    getUserJoinedQuests: () => {
        return axios.get(to("quest/joined/user"));
    }
};
