import './axios-config';

import { auth } from "./drivers/auth.driver";
import { faction } from "./drivers/faction.driver";
import { guild } from "./drivers/guild.driver";
import { land } from "./drivers/land.driver";
import { landWars } from "./drivers/land-wars.driver";
import { leasingCard } from "./drivers/leasingCard.driver";
import { quest } from "./drivers/quest.driver";
import { questParty } from "./drivers/questParty.driver";
import { reforgeContract } from "./drivers/reforgeContract.driver";
import { sdm } from "./drivers/sdm.driver";
import { soldiers } from "./drivers/soldiers.driver";
import { staking } from "./drivers/staking.driver";
import { tournament } from "./drivers/tournament.driver";
import { user } from "./drivers/user.driver";
import { tokenomic} from "./drivers/tokenomic.driver";
import { landGuildWars } from "./drivers/land-guild-wars.driver";
import {darkcountryItem} from "./drivers/darkcountryItems.driver";
import {market} from "./drivers/market.driver";


export const Api = {
    auth,
    faction,
    guild,
    land,
    landWars,
    leasingCard,
    quest,
    questParty,
    reforgeContract,
    sdm,
    soldiers,
    staking,
    tournament,
    user,
    tokenomic,
    landGuildWars,
    darkcountryItem,
    market,
};
