import {
    UPDATE_USER_LANDS,
    SET_ACTIVE_USER_LAND,
    SHOW_LAND_OWNER_INFO,
    UPDATE_LAND_NAME,
    MAKE_MESSAGES_READ,
    DELETE_MESSAGE,
    STAKE_SDM_TO_FACTION,
    UNSTAKE_SDM_FROM_FACTION
} from "../actionTypes/land.action-types";

export const updateUserLands = (updatedLand) => {
    return {
        type: UPDATE_USER_LANDS,
        payload: updatedLand
    }
};

export const setActiveUserLand = (land) => {
    return {
        type: SET_ACTIVE_USER_LAND,
        payload: land
    }
};

export const setShowLandOwnerInfo = (payload) => {
    return {
        type: SHOW_LAND_OWNER_INFO,
        payload
    }
};

export const updateLandName = (landID, name) => {
    return {
        type: UPDATE_LAND_NAME,
        payload: { landID, name }
    }
};

export const makeMessagesRead = () => {
    return { type: MAKE_MESSAGES_READ }
};

export const deleteMessage = (messageID) => {
    return {
        type: DELETE_MESSAGE,
        payload: { messageID }
    }
};

export const stakeSdmToFaction = ({ factionName, sdmValue, blockchain, userID }) => {
    return {
        type: STAKE_SDM_TO_FACTION,
        payload: { factionName, sdmValue, blockchain, userID }
    }
};

export const unstakeSdmFromFaction = ({ factionName, sdmValue, blockchain, userID }) => {
    return {
        type: UNSTAKE_SDM_FROM_FACTION,
        payload: { factionName, sdmValue, blockchain, userID }
    }
};
