import * as fcl from "@blocto/fcl";


import {
  FUNGIBLE_TOKEN_ADDRESS,
  DARKCOUNTRY_SDM_ADDRESS,
} from "../../../constants/flow.constants";

const CODE = `
    import FungibleToken from ${FUNGIBLE_TOKEN_ADDRESS}
    import SdmToken from ${DARKCOUNTRY_SDM_ADDRESS}

    pub fun main(address: Address): UFix64 {
      let account = getAccount(address)
    
      let vaultRef = account
        .getCapability(/public/sdmTokenBalance)
        .borrow<&SdmToken.Vault{FungibleToken.Balance}>()
        ?? panic("Could not borrow Balance capability")
    
      return vaultRef.balance
    }
`;

export async function readUserFlowSdmBalance({ address }) {
  return await fcl.query({
    cadence: CODE,
    args: (arg, t) => [arg(address, t.Address)]
  });
}