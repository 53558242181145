import { SDM } from "../constants/token.constants";
import { DARKCOUNTRY_CONTRACT } from "../constants/wax.constants";
import { mapWaxItemsResponse } from "../helpers/response.helper";
import { getDataByAssetIDs, getDataFromAtomicApi } from "../helpers/atomicApi";
import { fetchRows, getTableData } from "../helpers/waxChain.helper";

const getCollectionsAndSchemasForStaking = async () => {
    return await getTableData({
        contract: DARKCOUNTRY_CONTRACT,
        scope: DARKCOUNTRY_CONTRACT,
        table: 'stakingconf'
    });
};

export const getWalletWaxSdmBalance = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: DARKCOUNTRY_CONTRACT,
        scope: account,
        table: 'accounts',
        limit: 1,
    });

    if (!rows[0] || !rows[0].balance)
        return `0 ${SDM}`;

    const [value, currency] = rows[0].balance.split(' ');
    return `${Number(value)} ${currency}`;
};

export const fetchUserStakedWaxItems = async ({ account }) => {
    const { rows } = await fetchRows({
        contract: DARKCOUNTRY_CONTRACT,
        scope: DARKCOUNTRY_CONTRACT,
        table: "stakes",
        limit: 1,
        lowerBound: account,
        upperBound: account
    });

    const nftIDs = rows[0] ? rows[0]?.staked_nfts.map((nftID) => nftID) : [];

    if (!nftIDs.length) return [];

    return await getDataByAssetIDs(nftIDs);
};

export const fetchUserWaxItems = async ({ account, stakedItemsIDs, lockedItems }) => {
    const collections = await getCollectionsAndSchemasForStaking();

    const soulCardTemplates = ['30239','430563']; // 621545 - for testnet
    const pagesLimit = 10;
    const items = [];

    console.log(items);

    for (const { collecttion, schemas } of collections) {
        for (let i = 0; i < pagesLimit; i++) {
            const data = await getDataFromAtomicApi(`assets?owner=${account}&collection_name=${collecttion}&limit=1000&page=${i + 1}`);

            if (!data || !data.length)
                break;

            items.push(
               ...data.filter(
                 ({ schema: { schema_name }, template: { template_id } }) => {
                   if (schema_name === "other") {
                     return soulCardTemplates.includes(template_id);
                   } else {
                     return schemas.includes(schema_name);
                   }
                 }
               )
            );
        }
    }
    
    const stakedItems = await fetchUserStakedWaxItems({ account });

    return mapWaxItemsResponse({
        items: [...items, ...stakedItems],
        stakedItemsIDs,
        lockedItems
    });
};

export const fetchUserTicket = async ({ account }) => {
    const collections = await getCollectionsAndSchemasForStaking();

    const tickets = [];

    for (const { collecttion } of collections){
       const ticketData = await getDataFromAtomicApi(`assets?owner=${account}&collection_name=${collecttion}&schema_name=other&page=1&limit=100`);

       tickets.push(ticketData);
    }

    return tickets;
};

export const stakeNfts = async ({ activeUser, nftIDs }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    from: activeUser.accountName,
                    to: DARKCOUNTRY_CONTRACT,
                    asset_ids: nftIDs.map(nftID => Number(nftID)),
                    memo: "stake"
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const signEmptyAction = async ({ activeUser, actionName }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: DARKCOUNTRY_CONTRACT,
                name: actionName,
                authorization: [
                    {
                        actor: activeUser.accountName,
                        permission: "active"
                    }
                ],
                data: {
                    user: activeUser.accountName
                }
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const sdmDeposit = async ({ activeUser, value }) => {
    await activeUser.signTransaction({
        actions: [
            {
                account: DARKCOUNTRY_CONTRACT,
                name: 'transfer',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: activeUser.accountName,
                    to: DARKCOUNTRY_CONTRACT,
                    quantity: `${Number(value).toFixed(4)} ${SDM}`,
                    memo: 'deposit',
                },
            }
        ]
    }, {
        blocksBehind: 3,
        expireSeconds: 30
    });
};

export const tournamentTicket = async ({ activeUser, tickets }) => {
    const actions = []

    for (let i = 0; i < tickets.length; i++) {
        actions.push(
            {
                account: 'atomicassets',
                name: 'burnasset',
                authorization: [{
                    actor: activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    asset_owner: activeUser.accountName,
                    asset_id: tickets[i],
                },
            },
        );
    }

    await activeUser.signTransaction({ actions }, { blocksBehind: 3, expireSeconds: 30 });
};
