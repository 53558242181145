import * as fcl from "@blocto/fcl";

import {
  NON_FUNGIBLE_TOKEN_ADDRESS,
  DARKCOUNTRY_ADDRESS,
} from "../../../constants/flow.constants";

const CODE = `
    import NonFungibleToken from ${NON_FUNGIBLE_TOKEN_ADDRESS} 
    import DarkCountry from ${DARKCOUNTRY_ADDRESS} 
    
    // This script returns an array of all the NFT IDs in an account's collection.
    
    pub fun main(address: Address): [UInt64] {
        let account = getAccount(address)
    
        let collectionRef = account.getCapability(DarkCountry.CollectionPublicPath)!.borrow<&{DarkCountry.DarkCountryCollectionPublic}>()
            ?? panic("Could not borrow capability from public collection")
    
        return collectionRef.getIDs()
    }
`;

export async function readCollectionIds({ address }) {
  return await fcl.query({
    cadence: CODE,
    args: (arg, t) => [arg(address, t.Address)]
  });
}
