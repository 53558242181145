//AUTH
export const OPEN_SIGN_IN_MODAL = "OPEN_SIGN_IN_MODAL";
export const OPEN_SIGN_UP_MODAL = "OPEN_SIGN_UP_MODAL";
export const OPEN_TWO_FACTOR_AUTHENTICATION_MODAL = "OPEN_TWO_FACTOR_AUTHENTICATION_MODAL";

//SETTINGS
export const OPEN_CONFIRM_EMAIL_MODAL = "OPEN_CONFIRM_EMAIL_MODAL";
export const OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL = "OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL";
export const OPEN_ENTER_OLD_PASSWORD_MODAL = "OPEN_ENTER_OLD_PASSWORD_MODAL";
export const OPEN_COMBINE_ACCOUNTS_MODAL = "OPEN_COMBINE_ACCOUNTS_MODAL";

//LANDS
export const OPEN_NO_ACTIVE_LAND_MODAL = "OPEN_NO_ACTIVE_LAND_MODAL";
export const OPEN_CHOOSE_FACTION_ALERT_MODAL = "OPEN_CHOOSE_FACTION_ALERT_MODAL";
export const INFLUENCE_DEFLATION_RATE_MODAL = "INFLUENCE_DEFLATION_RATE_MODAL";

//PROFILE
export const TOURNAMENT_PROFILE_MODAL = "TOURNAMENT_PROFILE_MODAL";
