export const FILTER_OPTION = {
    BLOCKCHAIN: 'Blockchain',
    TYPE: 'Type',
    RARITY: 'Rarity',
    STATUS: 'Status',
    SCHEMA: 'Schema',
};

export const FILTER_RARITY = {
    COMMON: 'Common',
    RARE: 'Rare',
    EPIC: 'Epic',
    LEGENDARY: 'Legendary',
    MYTHICAL: 'Mythical',
    DC_RARITY: 'DC'
};

export const FILTER_TYPE = {
    SOUL: 'Soul',
    LANDS: 'Lands',
    CARDS: 'Cards',
    HEROES: 'Heroes'
};

export const MAX_ITEMS_FOR_ONE_STAKE = 350;

export const UNSTAKE_DAYS_DELAY = 3;
