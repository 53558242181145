import { call, put, all, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import { Api } from "../../api";
import { handleError } from "../../helpers/error.helper";
import {
    USER_SET_UP_EMAIL_AND_PASSWORD_REQUEST,
    USER_SET_UP_EMAIL_AND_PASSWORD_FAIL
} from "../actions/actionTypes/user.action-types";
import {
    OPEN_COMBINE_ACCOUNTS_MODAL,
    OPEN_CONFIRM_EMAIL_MODAL,
    OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL
} from "../actions/actionTypes/modal.action-types";
import {
    LOCAL_STORAGE_WAX_ACCOUNT,
    LOCAL_STORAGE_FLOW_ACCOUNT,
    LOCAL_STORAGE_EMAIL_TO_JOIN
} from "../../constants/localStorage.constants";

function* setUpEmailAndPassword(action) {
    try {
        const { email, password } = action.payload;

        const waxAccount = localStorage.getItem(LOCAL_STORAGE_WAX_ACCOUNT);
        const flowAccount = localStorage.getItem(LOCAL_STORAGE_FLOW_ACCOUNT);

        try {
            if (waxAccount) {
                yield call(Api.auth.waxSingUp, { waxAccount, email, password });
            } else {
                yield call(Api.auth.flowSingUp, { flowAccount, email, password });
            }
        } catch (e) {
            if (e.response.data.message.includes('This email is already taken')) {
                localStorage.setItem(LOCAL_STORAGE_EMAIL_TO_JOIN, email);

                yield put({
                    type: OPEN_COMBINE_ACCOUNTS_MODAL,
                    payload: true
                });

                yield put({
                    type: OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL,
                    payload: false
                });

                return;
            } else {
                throw e;
            }
        }

        yield put({
            type: OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL,
            payload: false
        });

        toast.success('Success');

        yield put({
            type: OPEN_CONFIRM_EMAIL_MODAL,
            payload: true
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_SET_UP_EMAIL_AND_PASSWORD_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* setUpEmailAndPasswordSaga() {
    yield takeLatest(USER_SET_UP_EMAIL_AND_PASSWORD_REQUEST, setUpEmailAndPassword);
}

export function* userSagas() {
    yield all([
        call(setUpEmailAndPasswordSaga)
    ])
}
