export const WAX_CHAIN_ID = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12'
    : '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4';

export const WAX_RPC_ENDPOINTS_HOST = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? "wax-testnet.eosphere.io"
    : "wax.cryptolions.io";

export const WAX_RPC_ENDPOINTS_PROTOCOL = "https";

export const DARKCOUNTRY_CONTRACT = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? 'darkcountrys'
    : 'darkcountryn';

