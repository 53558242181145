import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { USER_PROFILE_REQUEST } from "./redux/actions/actionTypes/auth.action-types";
import { GUILDS_REQUEST, LANDS_GUILDS_REQUEST } from "./redux/actions/actionTypes/guild.action-types";
import { LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_USER_ID } from "./constants/localStorage.constants";
import { OPEN_CHOOSE_FACTION_ALERT_MODAL } from "./redux/actions/actionTypes/modal.action-types";
import {
    FACTIONS_STAKES_REQUEST,
    INFLUENCE_LEADERBOARD_REQUEST,
    USER_LANDS_REQUEST
} from "./redux/actions/actionTypes/land.action-types";
import {
    ACTIVE_REFORGE_CONTRACTS_REQUEST,
    USER_JOINED_REFORGE_CONTRACTS_REQUEST
} from "./redux/actions/actionTypes/reforgeContract.action-types";
import {
    ACTIVE_QUESTS_REQUEST,
    USER_JOINED_QUESTS_REQUEST,
    USER_QUESTS_PARTIES_REQUEST
} from "./redux/actions/actionTypes/quest.action-types";
import { fetchFlowUserItems, fetchStakedUserItems, fetchWaxUserItems,fetchDarkCountryUserItems } from "./helpers/items.helper";
import { setActiveReforgeContractsForUser } from "./redux/actions/actionCreators/reforgeContracts.action-creators";
import { getFromLocalStorageWithExpiry } from "./helpers/storage.helper";
import { setActiveQuestsForUser } from "./redux/actions/actionCreators/quests.action-creators";
import { updateModalVisible } from "./redux/actions/actionCreators/modals.action-creators";
import { setActiveUserLand } from "./redux/actions/actionCreators/lands.action-creators";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Modals = lazy(() => import('./containers/Modals/Modals'));
const Home = lazy(() => import('./containers/Home/Home'));
const Profile = lazy(() => import('./containers/Profile/Profile'));
const AuctionsContainer = lazy(() => import('./containers/AuctionsContainer/AuctionsContainer'));
const Auction = lazy(() => import('./containers/Auction/Auction'));
const Blending = lazy(() => import('./containers/Blending/Blending'));
const BlendingIntro = lazy(() => import('./containers/BlendingIntro/BlendingIntro'));
const Lands = lazy(() => import('./containers/Lands/Lands'));
const Staking = lazy(() => import('./containers/Staking/Staking'));
const Bank = lazy(() => import('./containers/Bank/Bank'));
const Saloon = lazy(() => import('./containers/Saloon/Saloon'));
const SheriffsOffice = lazy(() => import('./containers/SheriffsOffice/SheriffsOffice'));

const TownHall = lazy(() => import('./containers/TownHall/TownHall'));
const GuildHouse = lazy(() => import('./containers/GuildHouse/GuildHouse'));
const GuildHouseNew = lazy(() => import('./containers/GuildHouseNew/GuildHouseNew'));
const MercenaryOutpost = lazy(() => import('./containers/MercenaryOutpost/MercenaryOutpost'));
const ConfirmEmail = lazy(() => import('./containers/ConfirmEmail/ConfirmEmail'));
const ChangeEmail = lazy(() => import('./containers/ChangeEmail/ChangeEmail'));
const UpdateEmail = lazy(() => import('./containers/UpdateEmail/UpdateEmail'));
const ResetPassword = lazy(() => import('./containers/ResetPassword/ResetPassword'));
const CombineAccounts = lazy(() => import('./containers/CombineAccounts/CombineAccounts'));
const Faction = lazy(() => import('./containers/Faction/Faction'));
const Privacy = lazy(() => import('./containers/Privacy/Privacy'));
const Terms = lazy(() => import('./containers/Terms/Terms'));
const Packs = lazy(() => import('./containers/Packs/Packs'));
const Tokenomics = lazy(() => import('./containers/Tokenomics/Tokenomics'));


const LandInfo = lazy(() => import('./containers/LandInfo/LandInfo'));

export const routes = [];

export default function App() {
    const dispatch = useDispatch();
    const location = useLocation();

    const user = useSelector(({ auth }) => auth.auth);
    const { guildsFetched, landsGuildsFetched, landsGuildsLoading } = useSelector(({ guild }) => guild);
    const { activeQuests, activeQuestsFetched, userJoinedQuestsFetched } = useSelector(({ quest }) => quest);
    const {
        activeReforgeContracts, activeReforgeContractsFetched
    } = useSelector(({ reforgeContract }) => reforgeContract);
    const {
        userLands, userLandsFetched, activeUserLand, loading, influenceLeaderboardFetched,
        factionsStakesFetched, factionsStakesLoading
    } = useSelector(({ lands }) => lands);
    const {
        fetchingStakedItems, stakedItemsFetched, stakedItems, lockedItems, waxItemsFetched, flowItemsFetched, darkCountryItemsFetched,
        itemsInQuestsPartiesFetched, itemsInReforgeContractsFetched,
    } = useSelector(({ items }) => items);

    useEffect(() => {
        const userID = localStorage.getItem(LOCAL_STORAGE_USER_ID);
        const refreshToken = getFromLocalStorageWithExpiry({ key: LOCAL_STORAGE_REFRESH_TOKEN });

        if (userID && refreshToken) {
            dispatch({
                type: USER_PROFILE_REQUEST,
                payload: { userID }
            });
        }
    }, [dispatch]);

    useEffect(() => {
        if (!influenceLeaderboardFetched)
            dispatch({ type: INFLUENCE_LEADERBOARD_REQUEST });
    }, [dispatch, influenceLeaderboardFetched]);

    useEffect(() => {
        if (!guildsFetched)
            dispatch({ type: GUILDS_REQUEST });
    }, [dispatch, guildsFetched]);

    useEffect(() => {
        if (user.id)
            dispatch({ type: USER_LANDS_REQUEST });
    }, [user, dispatch]);

    useEffect(() => {
        if (!activeQuestsFetched && user.id)
            dispatch({ type: ACTIVE_QUESTS_REQUEST });
    }, [user, activeQuestsFetched, dispatch]);

    useEffect(() => {
        if (!userJoinedQuestsFetched && user.id)
            dispatch({ type: USER_JOINED_QUESTS_REQUEST });
    }, [user, dispatch, userJoinedQuestsFetched]);

    useEffect(() => {
        if (!itemsInQuestsPartiesFetched && user.id)
            dispatch({ type: USER_QUESTS_PARTIES_REQUEST });
    }, [user, dispatch, itemsInQuestsPartiesFetched]);

    useEffect(() => {
        if (!activeReforgeContractsFetched && user.id)
            dispatch({ type: ACTIVE_REFORGE_CONTRACTS_REQUEST });
    }, [user, activeReforgeContractsFetched, dispatch]);

    useEffect(() => {
        if (!itemsInReforgeContractsFetched && user.id)
            dispatch({ type: USER_JOINED_REFORGE_CONTRACTS_REQUEST });
    }, [user, dispatch, itemsInReforgeContractsFetched]);

    useEffect(() => {
        if (userLandsFetched && activeQuests && activeQuests.length) {
            dispatch(setActiveQuestsForUser(activeQuests));
        }
    }, [activeQuests, userLands, userLandsFetched, dispatch]);

    useEffect(() => {
        if (userLandsFetched && activeReforgeContracts.length) {
            dispatch(setActiveReforgeContractsForUser(activeReforgeContracts));
        }
    }, [activeReforgeContracts, userLands, userLandsFetched, dispatch]);

    useEffect(() => {
        if (!loading && !activeUserLand && userLands && userLands[0]) {
            const land = userLands[0];
            land.quests.sort((quest1, quest2) => quest1.status.localeCompare(quest2.status));

            dispatch(setActiveUserLand(land));
        }
    }, [loading, activeUserLand, userLands, dispatch]);

    useEffect(() => {
        if (!landsGuildsFetched && !landsGuildsLoading && userLandsFetched && userLands.length) {
            dispatch({
                type: LANDS_GUILDS_REQUEST,
                payload: userLands.map(({ _id }) => _id)
            });
        }
    }, [landsGuildsFetched, landsGuildsLoading, userLands, userLandsFetched, dispatch]);

    useEffect(() => {
        if (user.id && !factionsStakesFetched && !factionsStakesLoading)
            dispatch({ type: FACTIONS_STAKES_REQUEST });
    }, [user, factionsStakesFetched, factionsStakesLoading, dispatch]);

    useEffect(() => {
        if (location.pathname.includes('lands')) {
            const landWithoutFaction = userLands.find(({ faction }) => !faction);

            if (landWithoutFaction)
                dispatch(updateModalVisible(OPEN_CHOOSE_FACTION_ALERT_MODAL, true));
        }
    }, [userLands, dispatch, location])

    return (
        // <div className={'maintenance'}>
        //     <p>Game on maintenance</p>
        // </div>

        <Router>
            <Suspense fallback={<></>}>
                <ScrollToTop />
                <Header />

                <Switch>
                    <Route path={'/'} component={Home} exact />

                    <Route path={'/profile'} component={Profile} exact />

                    <Route path={'/auctions'} component={AuctionsContainer} exact />
                    <Route path={'/auction/:id'} component={Auction} exact />
                    <Route path={'/blending'} component={Blending} exact />
                    <Route path={'/blending-intro'} component={BlendingIntro} exact />
                    <Route path={'/lands'} component={Lands} exact />
                    <Route path={'/staking'} component={Staking} exact />
                    <Route path={'/bank'} component={Bank} exact />
                    <Route path={'/sheriffs-office'} component={SheriffsOffice} exact />
                    <Route path={'/town-hall'} component={TownHall} exact />
                    <Route path={'/saloon'} component={Saloon} exact />
                    <Route path={'/guild-house'} component={GuildHouseNew} exact /> 
                    <Route path={'/barracks'} component={GuildHouseNew} exact />
                    <Route path={'/mercenary-outpost'} component={MercenaryOutpost} exact />

                    <Route path={'/confirm-email'} component={ConfirmEmail} exact />
                    <Route path={'/change-email'} component={ChangeEmail} exact />
                    <Route path={'/update-email'} component={UpdateEmail} exact />
                    <Route path={'/reset-password'} component={ResetPassword} exact />
                    <Route path={'/combine-accounts'} component={CombineAccounts} exact />

                    <Route path={'/faction'} component={Faction} exact />

                    <Route path={'/privacy'} component={Privacy} exact />
                    <Route path={'/terms'} component={Terms} exact />
                    <Route path={'/tokenomics'} component={Tokenomics} exact />

                    <Route path={'/land-info'} component={LandInfo} exact />
                    {/* <Route path={'/auctions'} component={AuctionsContainer} exact />
                    <Route path={'/auction/:id'} component={Auction} exact /> */}

                    <Route path={'/packs'} component={Packs} exact />
                    <Redirect to="/" />
                </Switch>

                <Footer />

                <Modals />

                <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                />
            </Suspense>
        </Router>
    );
}
