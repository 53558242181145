import {
    USER_STAKED_ITEMS_REQUEST,
    USER_STAKED_ITEMS_SUCCESS,
    USER_STAKED_ITEMS_FAIL,

    USER_WAX_ITEMS_REQUEST,
    USER_WAX_ITEMS_SUCCESS,
    USER_WAX_ITEMS_FAIL,

    USER_FLOW_ITEMS_REQUEST,
    USER_FLOW_ITEMS_SUCCESS,
    USER_FLOW_ITEMS_FAIL,

    ITEMS_IN_QUESTS_PARTIES_SUCCESS,
    ITEMS_IN_QUESTS_PARTIES_FAIL,

    ITEMS_IN_REFORGE_CONTRACTS_SUCCESS,
    ITEMS_IN_REFORGE_CONTRACTS_FAIL,

    USER_DARKCOUNTRY_ITEMS_REQUEST,
    USER_DARKCOUNTRY_ITEMS_SUCCESS,
    USER_DARKCOUNTRY_ITEMS_FAIL,
    USER_WAX_UPDATE_SOUL_CARD,
    USER_IMX_ITEMS_REQUEST,
    USER_IMX_ITEMS_SUCCESS,
    USER_IMX_ITEMS_FAIL,
    USER_APTOS_ITEMS_REQUEST,
    USER_APTOS_ITEMS_SUCCESS,
    USER_APTOS_ITEMS_FAIL,
} from '../actions/actionTypes/item.action-types';
import {
    SIGN_OUT_SUCCESS
} from "../actions/actionTypes/auth.action-types";
import { APTOS, DARKCOUNTRY, FLOW, WAX } from '../../constants/chain.constants';

const initialState = {
    stakedItems: [],
    lockedItems: [],
    fetchingStakedItems: false,
    stakedItemsFetched: {WAX: false, FLOW: false, APTOS: false},

    imxItems: [],
    fetchingImxItemsLoading: false,
    imxItemsFetched: false,

    waxItems: [],
    fetchingWaxItemsLoading: false,
    waxItemsFetched: false,

    aptosItems: [],
    fetchingAptosItemsLoading: false,
    aptosItemsFetched: false,

    flowItems: [],
    fetchingFlowItemsLoading: false,
    flowItemsFetched: false,

    darkCountryItems:[],
    fetchingDarkCountryItemsLoading: false,
    darkCountryItemsFetched: false,

    itemsInQuestsParties: [],
    itemsInQuestsPartiesFetched: false,

    itemsInReforgeContracts: [],
    itemsInReforgeContractsFetched: false,
    
    leasedCards: [],
    errorText: ''
};

export default function items(state = initialState, action) {
    switch (action.type) {
        //STAKED
        case USER_STAKED_ITEMS_REQUEST:
            return {
                ...state,
                fetchingStakedItems: true,
                stakedItemsFetched: action.payload.blockchain === WAX
                ? {...state.stakedItemsFetched, WAX: false}
                : action.payload.blockchain === FLOW
                ? {...state.stakedItemsFetched, FLOW: false }
                : action.payload.blockchain === APTOS
                ? {...state.stakedItemsFetched, APTOS: false }
                : {WAX: false, FLOW: false, APTOS: false},
                waxItemsFetched: action.payload.blockchain === WAX ? false : state.waxItemsFetched,
                flowItemsFetched: action.payload.blockchain === FLOW ? false : state.flowItemsFetched,
                aptosItemsFetched: action.payload.blockchain === APTOS ? false : state.aptosItemsFetched,
                darkCountryItemsFetched: action.payload.blockchain === DARKCOUNTRY ? false : state.darkCountryItemsFetched,
            };

        case USER_STAKED_ITEMS_SUCCESS:
            return {
              ...state,
              stakedItems: action.payload.data.stakedItems,
              lockedItems: action.payload.data.lockedItems,
              leasedCards: action.payload.data.leasedCards.filter((x) => !!x),
              stakedItemsFetched:
                action.payload.blockchain === WAX
                  ? {...state.stakedItemsFetched, WAX: true}
                  : action.payload.blockchain === FLOW
                  ? {...state.stakedItemsFetched, FLOW: true }
                  : action.payload.blockchain === APTOS
                  ? {...state.stakedItemsFetched, APTOS: true }
                  : {WAX: true, FLOW: true, APTOS: true },
              fetchingStakedItems: false,
            };

        case USER_STAKED_ITEMS_FAIL:
            return {
                ...state,
                stakedItems: [],
                lockedItems: [],
                leasedCards: [],
                darkcountryItems:[],
                errorText: action.payload.error,
                stakedItemsFetched:
                action.payload.blockchain === WAX
                  ? {FLOW:state.stakedItemsFetched.FLOW, APTOS: state.stakedItemsFetched.APTOS, WAX: true, }
                  : action.payload.blockchain === FLOW
                  ? {WAX: state.stakedItemsFetched.WAX, APTOS: state.stakedItemsFetched.APTOS, FLOW: true }
                  : action.payload.blockchain === APTOS
                  ? {WAX: state.stakedItemsFetched.WAX, FLOW: state.stakedItemsFetched.FLOW, APTOS: true }
                  : {WAX: true, FLOW: true, APTOS: true},
                fetchingStakedItems: false
            };

        //IMX
        case USER_IMX_ITEMS_REQUEST:
            return {
                ...state,
                fetchingImxItemsLoading: true
            };

        case USER_IMX_ITEMS_SUCCESS:
            return {
                ...state,
                imxItems: action.payload,
                fetchingImxItemsLoading: false,
                imxItemsFetched: true
            };

        case USER_IMX_ITEMS_FAIL:
            return {
                ...state,
                imxItems: [],
                errorText: action.payload.error,
                fetchingImxItemsLoading: false,
                imxItemsFetched: true
            };


        //WAX

        case USER_WAX_UPDATE_SOUL_CARD:
        return {
          ...state,
          waxItems: state.waxItems.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                cardType:action.payload.cardType,
                nextChangeAt: action.payload.nextChangeAt
            }
            } else {
              return item;
            }
          }),
        };

        case USER_WAX_ITEMS_REQUEST:
            return {
                ...state,
                fetchingWaxItemsLoading: true
            };

        case USER_WAX_ITEMS_SUCCESS:
            return {
                ...state,
                waxItems: action.payload,
                fetchingWaxItemsLoading: false,
                waxItemsFetched: true
            };

        case USER_WAX_ITEMS_FAIL:
            return {
                ...state,
                waxItems: [],
                errorText: action.payload.error,
                fetchingWaxItemsLoading: false,
                waxItemsFetched: true
            };

        //APTOS
        case USER_APTOS_ITEMS_REQUEST:
            return {
                ...state,
                fetchingAptosItemsLoading: true
            };

        case USER_APTOS_ITEMS_SUCCESS:
            return {
                ...state,
                aptosItems: action.payload,
                fetchingAptosItemsLoading: false,
                aptosItemsFetched: true,
            };

        case USER_APTOS_ITEMS_FAIL:
            return {
                ...state,
                aptosItems: [],
                errorText: action.payload.error,
                fetchingAptosItemsLoading: false,
                aptosItemsFetched: true,
            };

        //FLOW
        case USER_FLOW_ITEMS_REQUEST:
            return {
                ...state,
                fetchingFlowItemsLoading: true
            };

        case USER_FLOW_ITEMS_SUCCESS:
            return {
                ...state,
                flowItems: action.payload,
                fetchingFlowItemsLoading: false,
                flowItemsFetched: true,
            };

        case USER_FLOW_ITEMS_FAIL:
            return {
                ...state,
                flowItems: [],
                errorText: action.payload.error,
                fetchingFlowItemsLoading: false,
                flowItemsFetched: true,
            };

         //DARKCOUNTRY
         case USER_DARKCOUNTRY_ITEMS_REQUEST:
            return {
                ...state,
                fetchingDarkCountryItemsLoading: true
            };

        case USER_DARKCOUNTRY_ITEMS_SUCCESS:
            return {
                ...state,
                darkCountryItems: action.payload,
                fetchingDarkCountryItemsLoading: false,
                darkCountryItemsFetched: true
            };

        case USER_DARKCOUNTRY_ITEMS_FAIL:
            return {
                ...state,
                darkCountryItems: [],
                errorText: action.payload.error,
                fetchingDarkCountryItemsLoading: false,
                darkCountryItemsFetched: true
            };

    
        //ITEMS IN QUESTS PARTIES
        case ITEMS_IN_QUESTS_PARTIES_SUCCESS:
            return {
                ...state,
                itemsInQuestsParties: action.payload,
                itemsInQuestsPartiesFetched: true
            };

        case ITEMS_IN_QUESTS_PARTIES_FAIL:
            return {
                ...state,
                itemsInQuestsParties: [],
                itemsInQuestsPartiesFetched: true,
                errorText: action.payload.error,
            };

        //ITEMS IN REFORGE CONTRACTS
        case ITEMS_IN_REFORGE_CONTRACTS_SUCCESS:
            return {
                ...state,
                itemsInReforgeContracts: action.payload,
                itemsInReforgeContractsFetched: true
            };

        case ITEMS_IN_REFORGE_CONTRACTS_FAIL:
            return {
                ...state,
                itemsInReforgeContracts: [],
                itemsInReforgeContractsFetched: true,
                errorText: action.payload.error,
            };

        case SIGN_OUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};