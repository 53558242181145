import * as fcl from "@blocto/fcl";

import { tx } from "../utils/tx";

import { DARKCOUNTRY_STAKING_ADDRESS } from "../../../constants/flow.constants";

const CODE = `
    import DarkCountryStaking from ${DARKCOUNTRY_STAKING_ADDRESS}

    transaction(userAddress: Address, stakedNFTs: [UInt64]) {
        prepare(signer: AuthAccount) {
            DarkCountryStaking.requestSetStakedNFTsForAddress(userAddress: userAddress, stakedNFTs: stakedNFTs)
        }
    }
`;


export async function requestToStakeNfts({ userAddress, nfts: stakedNFTs }) {
  return await tx({
    cadence: CODE,
    args: (arg, t) => [
      arg(userAddress, t.Address),
      arg(stakedNFTs, t.Array(t.UInt64)),
    ],
    proposer: fcl.currentUser,
    payer: fcl.currentUser,
    authorizations: [fcl.currentUser],
    limit: 1000,
  });
}

