import { combineReducers } from 'redux';

import auth from './auth.reducer';
import guild from './guild.reducer';
import items from './items.reducer';
import lands from './lands.reducer';
import modal from './modal.reducer';
import quest from './quest.reducer';
import reforgeContract from "./reforgeContract.reducer";
import user from './user.reducer';

const reducers = combineReducers({
    auth,
    guild,
    items,
    lands,
    modal,
    quest,
    reforgeContract,
    user
});

export { reducers };
