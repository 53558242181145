import { call, put, all, takeLatest } from "redux-saga/effects";

import * as fcl from "@blocto/fcl";
import { toast } from "react-toastify";

import { store } from "../../";

import { Api } from "../../api";
import {
    EMAIL_SIGN_IN_REQUEST,
    EMAIL_SIGN_IN_SUCCESS,
    EMAIL_SIGN_IN_FAIL,
    GOOGLE_SIGN_IN_REQUEST,
    GOOGLE_SIGN_IN_SUCCESS,
    GOOGLE_SIGN_IN_FAIL,
    WAX_SIGN_IN_REQUEST,
    WAX_SIGN_IN_SUCCESS,
    WAX_SIGN_IN_FAIL,
    FLOW_SIGN_IN_REQUEST,
    FLOW_SIGN_IN_SUCCESS,
    FLOW_SIGN_IN_FAIL,
    APTOS_SIGN_IN_REQUEST,
    APTOS_SIGN_IN_SUCCESS,
    APTOS_SIGN_IN_FAIL,
    EMAIL_SIGN_UP_REQUEST,
    EMAIL_SIGN_UP_FAIL,
    SIGN_OUT_REQUEST,
    SIGN_OUT_SUCCESS,
    SIGN_OUT_FAIL,
    EMAIL_SIGN_UP_SUCCESS,
    USER_PROFILE_REQUEST,
    USER_PROFILE_FAIL,
    TWO_FACTOR_AUTHENTICATION_REQUEST,
    TWO_FACTOR_AUTHENTICATION_SUCCESS,
    TWO_FACTOR_AUTHENTICATION_FAIL,
} from "../actions/actionTypes/auth.action-types";
import {
    OPEN_CONFIRM_EMAIL_MODAL,
    OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL,
    OPEN_SIGN_IN_MODAL,
    OPEN_SIGN_UP_MODAL,
    OPEN_TWO_FACTOR_AUTHENTICATION_MODAL
} from "../actions/actionTypes/modal.action-types";
import {
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_REFRESH_TOKEN,
    LOCAL_STORAGE_USER_ID,
    LOCAL_STORAGE_WAX_ACCOUNT,
    LOCAL_STORAGE_APTOS_ACCOUNT,
    LOCAL_STORAGE_FLOW_ACCOUNT,
} from "../../constants/localStorage.constants";
import { updateModalVisible } from "../actions/actionCreators/modals.action-creators";
import { setAuthTokensToStorage } from "../../helpers/auth.helper";
import { handleError } from "../../helpers/error.helper";
import { encrypt } from "../../helpers/encrypt.helper";

const finishSignIn = async ({ accessToken, refreshToken, userID, successSignInActionType }) => {
    setAuthTokensToStorage({ accessToken, refreshToken });

    localStorage.setItem(LOCAL_STORAGE_USER_ID, userID);

    const { data } = await Api.user.getProfile({ userID });

    store.dispatch({
        type: successSignInActionType,
        payload: data
    });

    store.dispatch(updateModalVisible(OPEN_SIGN_IN_MODAL, false));
};

const setupingEmailAndPasswordAfterAuthorization = ({ waxAccount, flowAccount, aptosAccount }) => {
    if (waxAccount)
        localStorage.setItem(LOCAL_STORAGE_WAX_ACCOUNT, waxAccount);

    if (flowAccount)
        localStorage.setItem(LOCAL_STORAGE_FLOW_ACCOUNT, flowAccount);

    if (aptosAccount)
        localStorage.setItem(LOCAL_STORAGE_APTOS_ACCOUNT, aptosAccount);

    store.dispatch(updateModalVisible(OPEN_SIGN_IN_MODAL, false));
    store.dispatch(updateModalVisible(OPEN_SET_UP_EMAIL_AND_PASSWORD_MODAL, true));
};

const setupingTwoFactorAuthentication = ({ userID }) => {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, userID);

    store.dispatch(updateModalVisible(OPEN_SIGN_IN_MODAL, false));
    store.dispatch(updateModalVisible(OPEN_TWO_FACTOR_AUTHENTICATION_MODAL, true));
};

const finishTwoFactorAuthentication = () => {
    store.dispatch(updateModalVisible(OPEN_TWO_FACTOR_AUTHENTICATION_MODAL, false));
};

function* emailSignIn(action) {
    try {
        const { email, password, history } = action.payload;

        const {
            data: { userID, twoFactorAuthentication, tokens }
        } = yield call(Api.auth.emailSignIn, { email, password });

        if (twoFactorAuthentication)
            return setupingTwoFactorAuthentication({ userID });

        yield call(finishSignIn,{
            userID,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            successSignInActionType: EMAIL_SIGN_IN_SUCCESS
        });

        history.push('/lands');
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: EMAIL_SIGN_IN_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* googleSignIn(action) {
    try {
        const { email, googleID, googleName, token, history } = action.payload;

        const { status, data } = yield call(Api.auth.googleSingIn, { email, googleID, googleName, token });

        if (status === 201) {
            yield put({
                type: OPEN_SIGN_IN_MODAL,
                payload: false
            });

            yield put({
                type: OPEN_CONFIRM_EMAIL_MODAL,
                payload: true
            });
        }

        if (status === 200) {
            const { userID, tokens: { accessToken, refreshToken } } = data;

            yield call(finishSignIn,{
                accessToken,
                refreshToken,
                userID,
                successSignInActionType: GOOGLE_SIGN_IN_SUCCESS
            });

            const pathname = window.location.pathname
        
            if(pathname === "/") {
                history.push('/lands');
            }
        }
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: GOOGLE_SIGN_IN_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* waxSignIn(action) {
    try {
        const { waxAccount, history, wallet } = action.payload;

        const { data: { isUserExist } } = yield call(Api.user.checkIfWaxAccountExist, { waxAccount });

        if (!isUserExist)
            return setupingEmailAndPasswordAfterAuthorization({ waxAccount });

        const signature = encrypt(waxAccount);

        const {
            data: { userID, twoFactorAuthentication, tokens }
        } = yield call(Api.auth.waxSingIn, { waxAccount, signature, wallet});

        if (twoFactorAuthentication)
            return setupingTwoFactorAuthentication({ userID });

        yield call(finishSignIn,{
            userID,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            successSignInActionType: WAX_SIGN_IN_SUCCESS,
        });

        history.push('/lands');
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: WAX_SIGN_IN_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* flowSignIn(action) {
    try {
        const { flowAccount, history } = action.payload;

        const { data: { isUserExist } } = yield call(Api.user.checkIfFlowAccountExist, { flowAccount });

        if (!isUserExist)
            return setupingEmailAndPasswordAfterAuthorization({ flowAccount });

        const signature = encrypt(flowAccount);

        const {
            data: { userID, tokens, twoFactorAuthentication }
        } = yield call(Api.auth.flowSingIn, { flowAccount, signature });

        if (twoFactorAuthentication)
            return setupingTwoFactorAuthentication({ userID });

        yield call(finishSignIn,{
            userID,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            successSignInActionType: FLOW_SIGN_IN_SUCCESS,
        });

        history.push('/lands');
    } catch (error) {
        fcl.unauthenticate()

        const errorMessage = handleError({ error });

        yield put({
            type: FLOW_SIGN_IN_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* aptosSignIn(action) {
    try {

        const { aptosAccount, message, publicKey, signature,  history } = action.payload;

        const { data: { isUserExist } } = yield call(Api.user.checkIfAptosAccountExist, { aptosAccount });

        if (!isUserExist)
            return setupingEmailAndPasswordAfterAuthorization({ aptosAccount });

        const {
            data: { userID, tokens, twoFactorAuthentication }
        } = yield call(Api.auth.aptosSingIn, { aptosAccount, signature, message, publicKey });

        if (twoFactorAuthentication)
            return setupingTwoFactorAuthentication({ userID });

        yield call(finishSignIn,{
            userID,
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
            successSignInActionType: APTOS_SIGN_IN_SUCCESS,
        });

        history.push('/lands');
    } catch (error) {

        const errorMessage = handleError({ error });
        yield put({
            type: APTOS_SIGN_IN_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* twoFactorAuthentication(action) {
    try {
        const { userID, accessToken, refreshToken, history } = action.payload;

        yield call(finishSignIn,{
            accessToken,
            refreshToken,
            userID,
            successSignInActionType: TWO_FACTOR_AUTHENTICATION_SUCCESS
        });

        yield call(finishTwoFactorAuthentication);

        const pathname = window.location.pathname
        
        if(pathname === "/") {
            history.push('/lands');
        }
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: TWO_FACTOR_AUTHENTICATION_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* emailSignUp(action) {
    try {
        const { email, password } = action.payload;

        yield call(Api.auth.emailSignUp, { email, password });

        yield put({
            type: OPEN_SIGN_UP_MODAL,
            payload: false
        });

        toast.success('Success');

        yield put({
            type: OPEN_CONFIRM_EMAIL_MODAL,
            payload: true
        });

        yield put({
            type: EMAIL_SIGN_UP_SUCCESS,
            payload: {}
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: EMAIL_SIGN_UP_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserProfile(action) {
    try {
        const { userID } = action.payload;

        const { data } = yield call(Api.user.getProfile, { userID });

        if (data && data.emailConfirmed)
            yield put({
                type: EMAIL_SIGN_IN_SUCCESS,
                payload: data
            });
        else
            yield put({ type: USER_PROFILE_FAIL });
    } catch (error) {
        yield put({ type: USER_PROFILE_FAIL });
    }
}

function* signOut() {
    try {
        yield put({ type: SIGN_OUT_SUCCESS });

        yield call(Api.auth.signOut);
    } catch (error) {
        const errorMessage = handleError({ error, showToast: false });

        yield put({
            type: SIGN_OUT_FAIL,
            payload: { error: errorMessage }
        });
    } finally {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    }
}

function* emailSignInSaga() {
    yield takeLatest(EMAIL_SIGN_IN_REQUEST, emailSignIn);
}

function* googleSignInSaga() {
    yield takeLatest(GOOGLE_SIGN_IN_REQUEST, googleSignIn);
}

function* waxSignInSaga() {
    yield takeLatest(WAX_SIGN_IN_REQUEST, waxSignIn);
}

function* flowSignInSaga() {
    yield takeLatest(FLOW_SIGN_IN_REQUEST, flowSignIn);
}

function* aptosSignInSaga() {
    yield takeLatest(APTOS_SIGN_IN_REQUEST, aptosSignIn);
}

function* twoFactorAuthenticationSaga() {
    yield takeLatest(TWO_FACTOR_AUTHENTICATION_REQUEST, twoFactorAuthentication);
}

function* emailSignUpSaga() {
    yield takeLatest(EMAIL_SIGN_UP_REQUEST, emailSignUp);
}

function* getUserProfileSaga() {
    yield takeLatest(USER_PROFILE_REQUEST, getUserProfile);
}

function* signOutSaga() {
    yield takeLatest(SIGN_OUT_REQUEST, signOut);
}

export function* authSagas() {
    yield all([
        call(emailSignInSaga),
        call(googleSignInSaga),
        call(waxSignInSaga),
        call(flowSignInSaga),
        call(aptosSignInSaga),
        call(twoFactorAuthenticationSaga),
        call(emailSignUpSaga),
        call(getUserProfileSaga),
        call(signOutSaga)
    ]);
}