import {
    USER_LANDS_REQUEST,
    USER_LANDS_SUCCESS,
    USER_LANDS_FAIL,
    UPDATE_LAND_NAME,
    UPDATE_LAND,
    SET_ACTIVE_USER_LAND,
    SHOW_LAND_OWNER_INFO,
    UPDATE_USER_LANDS,
    INFLUENCE_LEADERBOARD_REQUEST,
    INFLUENCE_LEADERBOARD_SUCCESS,
    INFLUENCE_LEADERBOARD_FAIL,
    REMOVE_GUILD_WITH_APPLICANT_FROM_USER_LAND,
    DELETE_MESSAGE,
    MAKE_MESSAGES_READ,
    FACTIONS_STAKES_REQUEST,
    FACTIONS_STAKES_SUCCESS,
    FACTIONS_STAKES_FAIL,
    STAKE_SDM_TO_FACTION,
    UNSTAKE_SDM_FROM_FACTION,
} from "../actions/actionTypes/land.action-types";
import {
    REMOVE_SOLDIER_APPLICANT_FROM_USER_LANDS
} from "../actions/actionTypes/soldiers.action-types";
import { SIGN_OUT_SUCCESS } from "../actions/actionTypes/auth.action-types";
import { FLOW, WAX, APTOS } from "../../constants/chain.constants";

const initialState = {
    activeUserLand: null,
    showLandOwnerInfo: true,

    userLands: [],
    userLandsFetched: false,
    lockedLands: [],

    influenceLeaderboard: [],
    influenceLeaderboardLoading: false,
    influenceLeaderboardFetched: false,

    factionsStakes: [],
    factionsStakesLoading: false,
    factionsStakesFetched: false,

    loading: false,
};

export default function items(state = initialState, action) {
    switch (action.type) {
        case USER_LANDS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case USER_LANDS_SUCCESS:
            return {
                ...state,
                userLands: action.payload.combinedLands,
                lockedLands:action.payload.lockedLands,
                userLandsFetched: true,
                loading: false
            };

        case USER_LANDS_FAIL:
            return {
                ...state,
                userLands: [],
                lockedLands:[],
                userLandsFetched: true,
                loading: false
            };

        case UPDATE_USER_LANDS:
            return {
                ...state,
                userLands: state.userLands.map(userLand => {
                    return userLand._id === action.payload._id ? action.payload : userLand;
                })
            };

        case REMOVE_GUILD_WITH_APPLICANT_FROM_USER_LAND: {
            const { guildID, landID } = action.payload;

            return {
                ...state,
                userLands: state.userLands.map(userLand => {
                    if (userLand._id !== landID)
                        return userLand;

                    return {
                        ...userLand,
                        guildsWithApplicants: userLand.guildsWithApplicants
                            .filter(({ _id }) => _id !== guildID)
                    };
                })
            };
        }

        case REMOVE_SOLDIER_APPLICANT_FROM_USER_LANDS: {
            const { applicantUserID } = action.payload;

            return {
                ...state,
                userLands: state.userLands.map(userLand => {
                    return !userLand.soldiersApplicants.length
                        ? userLand
                        : {
                            ...userLand,
                            soldiersApplicants: userLand.soldiersApplicants
                                .filter(({ user: { _id } }) => _id !== applicantUserID)
                        };
                })
            }
        }

        case SET_ACTIVE_USER_LAND:
            return {
                ...state,
                activeUserLand: action.payload
            };

        case SHOW_LAND_OWNER_INFO:
            return {
                ...state,
                showLandOwnerInfo: action.payload
            };

        case INFLUENCE_LEADERBOARD_REQUEST:
            return {
                ...state,
                influenceLeaderboardLoading: true
            };

        case INFLUENCE_LEADERBOARD_SUCCESS:
            return  {
                ...state,
                influenceLeaderboardLoading: false,
                influenceLeaderboardFetched: true,
                influenceLeaderboard: action.payload
            };

        case INFLUENCE_LEADERBOARD_FAIL:
            return {
                ...state,
                influenceLeaderboardLoading: false,
                influenceLeaderboardFetched: true,
                influenceLeaderboard: []
            };

        case UPDATE_LAND_NAME: {
            const { landID, name } = action.payload;

            return {
                ...state,
                influenceLeaderboard: state.influenceLeaderboard.map(land => {
                    return land._id !== landID ? land : { ...land, name }
                })
            };
        }
        case UPDATE_LAND: {
            const { land} = action.payload;

            return {
                ...state,
                activeUserLand:  state.activeUserLand._id === land._id ? {...state.activeUserLand, soldiers: land.soldiers,
                    soldiersApplicants:land.soldiersApplicants,
                    recruits:land.recruits } : state.activeUserLand,
                userLands: state.userLands.map(userLand => {
                    if (userLand._id !== land._id)
                        return userLand;

                    return {
                        ...userLand,
                        soldiers: land.soldiers,
                        soldiersApplicants:land.soldiersApplicants,
                        recruits:land.recruits
                    };
                })
            };
        }

        case DELETE_MESSAGE: {
            const { messageID } = action.payload;

            const payoutHistory = state.activeUserLand.payoutHistory.filter(({ _id }) => _id !== messageID);
            const internalReports = state.activeUserLand.internalReports.filter(({ _id }) => _id !== messageID);

            return {
                ...state,
                activeUserLand: { ...state.activeUserLand, payoutHistory, internalReports }
            };
        }

        case MAKE_MESSAGES_READ: {
            const payoutHistory = state.activeUserLand.payoutHistory.map((message) => ({ ...message, read: true }));
            const internalReports = state.activeUserLand.internalReports.map((message) => ({ ...message, read: true }));

            return {
                ...state,
                activeUserLand: { ...state.activeUserLand, payoutHistory, internalReports }
            };
        }

        case FACTIONS_STAKES_REQUEST:
            return {
                ...state,
                factionsStakesLoading: true
            };

        case FACTIONS_STAKES_SUCCESS:
            return {
                ...state,
                factionsStakes: action.payload,
                factionsStakesFetched: true,
                factionsStakesLoading: false
            };

        case FACTIONS_STAKES_FAIL:
            return {
                ...state,
                factionsStakes: [],
                factionsStakesFetched: true,
                factionsStakesLoading: false
            };

        case STAKE_SDM_TO_FACTION: {
            const { factionName, sdmValue, blockchain, userID } = action.payload;

            const faction = state.factionsStakes.find(({ name }) => name.toLowerCase() === factionName.toLowerCase());

            if (blockchain === WAX)
                faction.totalWaxSdmStaked += +sdmValue;

            if (blockchain === FLOW)
                faction.totalFlowSdmStaked += +sdmValue;

            if (blockchain === APTOS)
                faction.totalAptosSdmStaked += +sdmValue;

            const landStakes = faction.stakes.find(({ user, blockchain: stakeBlockchain }) => {
                return user === userID && stakeBlockchain === blockchain;
            });

            if (landStakes) {
                landStakes.balance += +sdmValue;
                landStakes.lastStakeTimestamp = new Date().getTime();
            } else {
                faction.stakes.push(({
                    user: userID,
                    blockchain,
                    balance: +sdmValue,
                    lastStakeTimestamp: new Date().getTime()
                }));
            }

            return state;
        }

        case UNSTAKE_SDM_FROM_FACTION: {
            const { factionName, sdmValue, blockchain, userID } = action.payload;

            const faction = state.factionsStakes.find(({ name }) => name.toLowerCase() === factionName.toLowerCase());

            if (blockchain === WAX)
                faction.totalWaxSdmStaked -= +sdmValue;

            if (blockchain === FLOW)
                faction.totalFlowSdmStaked -= +sdmValue;

            if (blockchain === APTOS)
                faction.totalAptosSdmStaked -= +sdmValue;

            const landStakes = faction.stakes.find(({ user, blockchain: stakeBlockchain }) => {
                return user === userID && stakeBlockchain === blockchain;
            });

            landStakes.balance -= +sdmValue;

            return state;
        }

        case SIGN_OUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
