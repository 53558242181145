import {
    USER_SET_UP_EMAIL_AND_PASSWORD_REQUEST,
    USER_SET_UP_EMAIL_AND_PASSWORD_SUCCESS,
    USER_SET_UP_EMAIL_AND_PASSWORD_FAIL
} from "../actions/actionTypes/user.action-types";

const initialState = {
    loading: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
      case USER_SET_UP_EMAIL_AND_PASSWORD_REQUEST:
          return {
              ...state,
              loading: true
          };

      case USER_SET_UP_EMAIL_AND_PASSWORD_SUCCESS:
          return {
              ...state,
              loading: false
          };

      case USER_SET_UP_EMAIL_AND_PASSWORD_FAIL:
          return {
              ...state,
              loading: false
          };

      default:
          return initialState;
  }
};
