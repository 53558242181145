import axios from "axios";

import { to2 } from "../../helpers/api.helper";

export const reforgeContract = {
    createReforgeContract: ({ heroRarity, heroLevel, duration, sdmReward, landID }) => {
        return axios.post(to2("reforge-contract"), {
            heroRarity, heroLevel, duration, sdmReward, landID
        });
    },

    deleteReforgeContract: ({ reforgeContractID }) => {
        return axios.delete(to2(`reforge-contract?reforgeContractID=${reforgeContractID}`));
    },

    getAvailableReforgeContracts: () => {
        return axios.get(to2("reforge-contract"));
    },

    getUserJoinedReforgeContracts: () => {
        return axios.get(to2("reforge-contract/joined/user"));
    },

    joinReforgeContract: ({ reforgeContractID, heroAssetID, heroBlockchain }) => {
        return axios.post(to2("reforge-contract/join"), {
            reforgeContractID, heroAssetID, heroBlockchain
        });
    },
};
