import {
    EMAIL_SIGN_IN_REQUEST,
    EMAIL_SIGN_IN_SUCCESS,
    EMAIL_SIGN_IN_FAIL,

    EMAIL_SIGN_UP_REQUEST,
    EMAIL_SIGN_UP_SUCCESS,
    EMAIL_SIGN_UP_FAIL,

    GOOGLE_SIGN_IN_REQUEST,
    GOOGLE_SIGN_IN_SUCCESS,
    GOOGLE_SIGN_IN_FAIL,

    FLOW_SIGN_IN_REQUEST,
    FLOW_SIGN_IN_SUCCESS,
    FLOW_SIGN_IN_FAIL,

    WAX_SIGN_IN_REQUEST,
    WAX_SIGN_IN_SUCCESS,
    WAX_SIGN_IN_FAIL,

    APTOS_SIGN_IN_REQUEST,
    APTOS_SIGN_IN_SUCCESS,
    APTOS_SIGN_IN_FAIL,

    USER_PROFILE_REQUEST,
    USER_PROFILE_FAIL,

    SIGN_OUT_SUCCESS,

    TWO_FACTOR_AUTHENTICATION_REQUEST,
    TWO_FACTOR_AUTHENTICATION_SUCCESS,
    TWO_FACTOR_AUTHENTICATION_FAIL
} from '../actions/actionTypes/auth.action-types';
import {
    ADD_SDM_TO_USER_BALANCE,
    SUBTRACT_SDM_TO_USER_BALANCE,
    SET_SDM_TO_USER_BALANCE
} from "../actions/actionTypes/user.action-types";
import { WAX, FLOW, APTOS } from "../../constants/chain.constants";

const initialState = {
    auth: {},
    loggedIn: false,
    loading: false,
    errorText: null
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_SIGN_IN_REQUEST:
        case EMAIL_SIGN_IN_REQUEST:
        case WAX_SIGN_IN_REQUEST:
        case FLOW_SIGN_IN_REQUEST:
        case APTOS_SIGN_IN_REQUEST:
        case EMAIL_SIGN_UP_REQUEST:
        case USER_PROFILE_REQUEST:
        case TWO_FACTOR_AUTHENTICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GOOGLE_SIGN_IN_SUCCESS:
        case EMAIL_SIGN_IN_SUCCESS:
        case WAX_SIGN_IN_SUCCESS:
        case FLOW_SIGN_IN_SUCCESS:
        case APTOS_SIGN_IN_SUCCESS:
        case TWO_FACTOR_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                auth: action.payload,
            };

        case EMAIL_SIGN_IN_FAIL:
        case EMAIL_SIGN_UP_FAIL:
        case GOOGLE_SIGN_IN_FAIL:
        case APTOS_SIGN_IN_FAIL:
        case WAX_SIGN_IN_FAIL:
        case FLOW_SIGN_IN_FAIL:
        case TWO_FACTOR_AUTHENTICATION_FAIL:
            return {
                ...state,
                loggedIn: false,
                loading: false,
                errorText: action.payload.error,
            };

        case EMAIL_SIGN_UP_SUCCESS:
        case USER_PROFILE_FAIL:
            return  {
                ...state,
                loading: false
            };

        case ADD_SDM_TO_USER_BALANCE: {
            const { sdm, blockchain } = action.payload;

            return {
                ...state,
                auth: {
                    ...state.auth,
                    waxSdmBalance: blockchain === WAX ? +state.auth.waxSdmBalance + +sdm : state.auth.waxSdmBalance,
                    flowSdmBalance: blockchain === FLOW ? +state.auth.flowSdmBalance + +sdm : state.auth.flowSdmBalance,
                    aptosSdmBalance: blockchain === APTOS ? +state.auth.aptosSdmBalance + +sdm : state.auth.aptosSdmBalance,
                }
            };
        }

        case SUBTRACT_SDM_TO_USER_BALANCE: {
            const { sdm, blockchain } = action.payload;

            return {
                ...state,
                auth: {
                    ...state.auth,
                    waxSdmBalance: blockchain === WAX ? +state.auth.waxSdmBalance - +sdm : state.auth.waxSdmBalance,
                    flowSdmBalance: blockchain === FLOW ? +state.auth.flowSdmBalance - +sdm : state.auth.flowSdmBalance,
                    aptosSdmBalance: blockchain === APTOS ? +state.auth.aptosSdmBalance - +sdm : state.auth.aptosSdmBalance,
                }
            };
        }
        case SET_SDM_TO_USER_BALANCE: {
            const { sdm, blockchain } = action.payload;
            
            return {
                ...state,
                auth: {
                    ...state.auth,
                    waxSdmBalance: blockchain === WAX ?  +sdm : state.auth.waxSdmBalance,
                    flowSdmBalance: blockchain === FLOW ?  +sdm : state.auth.flowSdmBalance,
                    aptosSdmBalance: blockchain === APTOS ?  +sdm : state.auth.aptosSdmBalance,
                }
            };
        }

        case SIGN_OUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
