import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { Provider } from 'react-redux';
// import { logger } from "redux-logger/src";

import { UALProvider } from "ual-reactjs-renderer";
import { GoogleOAuthProvider } from '@react-oauth/google';

import reportWebVitals from './reportWebVitals';

import "rodal/lib/rodal.css";
import "./styles/index.scss";
import "./styles/scroll.scss";

import App from './App';

import { sagas } from "./redux/sagas";
import { reducers } from "./redux/reducers";
import { waxChain, waxAuthenticators } from "./blockchains";
import { GOOGLE_CLIENT_ID } from './config';
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { MartianWallet } from '@martianwallet/aptos-wallet-adapter';

const saga = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(...[saga]))
    // composeWithDevTools(applyMiddleware(...[saga, logger]))
);

saga.run(sagas);
const wallets  = [
    new PetraWallet(),
    new MartianWallet(),
  ]

const root = createRoot(document.getElementById('root'));

root.render(
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <UALProvider
            chains={[waxChain]}
            authenticators={waxAuthenticators}
            appName={'DarkCountry'}
        >
            <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}  > 
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
            </AptosWalletAdapterProvider>
        </UALProvider>
        </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
