import axios from "axios";

import { to,to3,toLocal } from "../../helpers/api.helper";

export const staking = {
    getUserStakedItems: ({ userID, blockchain}) => {
        const blockchainForRequest = blockchain ? blockchain : null;
        return axios.get(to(`staking/user/staked-items?id=${userID}&blockchain=${blockchainForRequest}`));
    },
    getSoulCardsTypes: () => {
        return axios.get(to3('soulcard/show-cards-types'));
    },
    changeSoulCards:({id, card_type }) => {
        return axios.post(to3('soulcard/change'), { id, card_type });
    },

    stakeFlowItems: ({ transactionID }) => {
        return axios.post(to("staking/stake/flow"), { transactionID });
    },

    stakeLostWaxItems: ({ itemsIDs }) => {
        return axios.post(to("staking/stake/wax/lost"), { itemsIDs });
    },

    stakeAptosItems: ({ itemsIDs }) => {
        return axios.post(to3("staking/stake/aptos"), { itemsIDs });
    },

    unstakeItems: ({ itemsIDs, blockchain }) => {
        return axios.patch(to("staking/unstake"), { itemsIDs, blockchain });
    },

    stakeDarkCountryItems: ({ itemsIDs }) => {
        return axios.post(to("staking/stake/darkcountry"), { itemsIDs });
    },

};
