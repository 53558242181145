import {
    ACTIVE_QUESTS_REQUEST,
    ACTIVE_QUESTS_SUCCESS,
    ACTIVE_QUESTS_FAIL,
    USER_JOINED_QUESTS_REQUEST,
    USER_JOINED_QUESTS_SUCCESS,
    USER_JOINED_QUESTS_FAIL,
    USER_QUESTS_PARTIES_REQUEST,
    USER_QUESTS_PARTIES_SUCCESS,
    USER_QUESTS_PARTIES_FAIL,
    SET_ACTIVE_QUESTS_FOR_USER,
    REMOVE_ACTIVE_QUEST,
    UPDATE_ACTIVE_QUESTS
} from "../actions/actionTypes/quest.action-types";
import {
    SIGN_OUT_SUCCESS
} from "../actions/actionTypes/auth.action-types";

const initialState = {
    activeQuests: [],
    activeQuestsFetched: false,

    activeQuestsForUser: [],

    userJoinedQuests: [],
    userJoinedQuestsFetched: false,

    userQuestsParties: [],
    userQuestsPartiesFetched: false,

    loading: false,


    totalActiveRequestForUser: 0,
    requestlimit: 10,
    currentPage: 1,
    totalPages: 10,
    hasNextPage: false,
    nextPage: 2,
    hasPrevPage: false,
    prevPage: null,
    pagingCounter: 1,
};

export default function items(state = initialState, action) {
    switch (action.type) {
        case ACTIVE_QUESTS_REQUEST:
        case USER_QUESTS_PARTIES_REQUEST:
        case USER_JOINED_QUESTS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ACTIVE_QUESTS_SUCCESS:
            return {
                ...state,
                activeQuests: action.payload.docs,
                activeQuestsFetched: true,
                totalActiveRequestForUser: action.payload.totalDocs,
                requestlimit: action.payload.limit,
                currentPage: action.payload.page,
                totalPages: action.payload.totalPages,
                hasNextPage: action.payload.hasNextPage,
                nextPage: action.payload.nextPage,
                hasPrevPage: action.payload.hasPrevPage,
                prevPage: action.payload.prevPage,
                pagingCounter: action.payload.pagingCounter,

                loading: false
            };

        case ACTIVE_QUESTS_FAIL:
            return {
                ...state,
                activeQuests: [],
                activeQuestsFetched: true,
                loading: false
            };

        case USER_JOINED_QUESTS_SUCCESS:
            return {
                ...state,
                userJoinedQuests: action.payload,
                userJoinedQuestsFetched: true,
                loading: false
            };

        case USER_JOINED_QUESTS_FAIL:
            return {
                ...state,
                userJoinedQuests: [],
                userJoinedQuestsFetched: true,
                loading: false
            };

        case USER_QUESTS_PARTIES_SUCCESS:
            return {
                ...state,
                userQuestsParties: action.payload,
                userQuestsPartiesFetched: true,
                loading: false
            };

        case USER_QUESTS_PARTIES_FAIL:
            return {
                ...state,
                userQuestsParties: [],
                userQuestsPartiesFetched: true,
                loading: false
            };

        case REMOVE_ACTIVE_QUEST:
            return {
                ...state,
                activeQuests: state.activeQuests.filter(({ _id }) => _id !== action.payload)
            };

        case UPDATE_ACTIVE_QUESTS:
            return {
                ...state,
                activeQuests: [...state.activeQuests, action.payload]
            };

        case SET_ACTIVE_QUESTS_FOR_USER:
            return {
                ...state,
                activeQuestsForUser: action.payload
            };

        case SIGN_OUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};
