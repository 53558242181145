import { all, call } from "redux-saga/effects";

import { authSagas } from "./auth.saga";
import { guildSaga } from "./guild.saga";
import { itemsSagas } from "./items.saga";
import { landsSagas } from "./lands.saga";
import { questSagas } from "./quest.saga";
import { reforgeContractSagas } from "./reforgeContract.saga";
import { userSagas } from "./user.saga";

export function* sagas() {
    yield all([
        call(authSagas),
        call(guildSaga),
        call(itemsSagas),
        call(landsSagas),
        call(questSagas),
        call(reforgeContractSagas),
        call(userSagas),
    ]);
}
