import {
    GUILDS_SUCCESS,
    GUILDS_FAIL,
    LANDS_GUILDS_REQUEST,
    LANDS_GUILDS_SUCCESS,
    LANDS_GUILDS_FAIL,
    LANDS_GUILD_UPDATE,
    UPDATE_GUILD,
    ADD_GUILD,
    REMOVE_GUILD,
    ADD_MEMBER_TO_GUILD,
    REMOVE_MEMBER_FROM_GUILD,
    GUILD_HOUSE_LOADING,
    ADD_APPLICANT_TO_GUILD,
    REMOVE_APPLICANT_FROM_GUILD
} from "../actions/actionTypes/guild.action-types";
import { SIGN_OUT_SUCCESS } from "../actions/actionTypes/auth.action-types";

const initialState = {
    guilds: [],
    guildsFetched: false,

    landsGuilds: [],
    landsGuildsFetched: false,
    landsGuildsLoading: false,

    loading: false,
};

export default function guild(state = initialState, action) {
    switch (action.type) {
        case GUILD_HOUSE_LOADING:
            return {
                ...state,
                loading: action.payload
            };

        case GUILDS_SUCCESS:
            return {
                ...state,
                guilds: action.payload,
                guildsFetched: true
            };

        case GUILDS_FAIL:
            return {
                ...state,
                guilds: [],
                guildsFetched: true
            };

        case LANDS_GUILDS_REQUEST:
            return {
                ...state,
                landsGuildsLoading: true
            };

        case LANDS_GUILDS_SUCCESS:
            return {
                ...state,
                landsGuilds: action.payload,
                landsGuildsFetched: true,
                landsGuildsLoading: false,
            };
        case LANDS_GUILD_UPDATE:
            return{
            ...state,
            landsGuilds:action.payload,
            landsGuildsFetched: true,
            landsGuildsLoading: false,
        }
        case LANDS_GUILDS_FAIL:
            return {
                ...state,
                landsGuilds: [],
                landsGuildsFetched: true,
                landsGuildsLoading: false
            };

        case UPDATE_GUILD: {
            const { guildID, dataToUpdate } = action.payload;

            return {
                ...state,
                guilds: state.guilds.map(guild => {
                    return guild._id !== guildID ? guild : { ...guild, ...dataToUpdate };
                }),
                landsGuilds: state.landsGuilds.map(guild => {
                    return guild._id !== guildID ? guild : { ...guild, ...dataToUpdate };
                })
            };
        }

        case ADD_GUILD:
            return {
                ...state,
                guilds: [...state.guilds, action.payload],
                landsGuilds: [...state.landsGuilds, action.payload],
            };

        case REMOVE_GUILD:
            return {
                ...state,
                guilds: state.guilds.filter(({ _id }) => _id !== action.payload),
                landsGuilds: state.landsGuilds.filter(({ _id }) => _id !== action.payload),
            };

        case ADD_MEMBER_TO_GUILD: {
            const { guildID, landID, memberLand, rank } = action.payload;

            return {
                ...state,
                guilds: state.guilds.map(guild => {
                    return guild._id !== guildID
                        ? guild
                        : {
                            ...guild,
                            totalInfluence: +guild.totalInfluence + +memberLand.influenceBalance,
                            members: [...guild.members, { land: memberLand, rank }]
                        }
                }),
                landsGuilds: state.landsGuilds.map(guild => {
                    return guild._id !== guildID
                        ? guild
                        : {
                            ...guild,
                            members: [...guild.members, { land: memberLand, rank }],
                            applicants: guild.applicants.filter(applicantLandID => applicantLandID !== landID)
                        }
                })
            };
        }

        case REMOVE_MEMBER_FROM_GUILD: {
            const { guildID, memberLandID } = action.payload;

            return {
                ...state,
                guilds: state.guilds.map(guild => {
                    if (guild._id !== guildID)
                        return guild;

                    const memberToDelete = guild.members.find(({ land: { _id } }) => _id === memberLandID);

                    return {
                        ...guild,
                        totalInfluence: Number(guild.totalInfluence) - Number(memberToDelete.land.influenceBalance),
                        members: guild.members.filter(({ land: { _id } }) => _id !== memberLandID)
                    }
                }),
                landsGuilds: state.landsGuilds.map(guild => {
                    return guild._id !== guildID
                        ? guild
                        : {
                            ...guild,
                            members: guild.members.filter(({ land: { _id } }) => _id !== memberLandID)
                        }
                })
            };
        }

        case ADD_APPLICANT_TO_GUILD: {
            const { guildID, landID } = action.payload;

            return {
                ...state,
                landsGuilds: state.landsGuilds.map(guild => {
                    return guild._id !== guildID ? guild : { ...guild, applicants: [...guild.applicants, landID] };
                })
            }
        }

        case REMOVE_APPLICANT_FROM_GUILD: {
            const { guildID, landID } = action.payload;

            return {
                ...state,
                landsGuilds: state.landsGuilds.map(guild => {
                    return guild._id !== guildID
                        ? guild
                        : {
                            ...guild,
                            applicants: guild.applicants.filter(applicantLandID => applicantLandID !== landID)
                        }
                })
            }
        }

        case SIGN_OUT_SUCCESS:
            return {
                ...initialState,
                guilds: state.guilds,
                guildsFetched: state.guildsFetched,
            };

        default:
            return state;
    }
};
