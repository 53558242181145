import axios from "axios";

import { to, to2, toLocal } from "../../helpers/api.helper";

export const market = {
    getAssetsForMarket: () => {
        return axios.get(to("market-assets"));
    },
    buySdmPack: ({ waveID, packID, blockchain, itemID, count = 1 }) => {
        // console.log({waveID,packID,blockchain,itemID});
        return axios.post(to("market-assets/buy"), { waveID: waveID, packID: packID, blockchain: blockchain, itemID: itemID, count: count });
    },
};