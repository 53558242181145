export const setToLocalStorageWithExpiry = ({ key, value, ttl }) => {
    localStorage.setItem(
        key,
        JSON.stringify({
            value,
            expiry: new Date().getTime() + ttl
        })
    );
};

export const getFromLocalStorageWithExpiry = ({ key }) => {
    try {
        const data = localStorage.getItem(key);

        if (!data)
            return null;

        const { expiry, value } = JSON.parse(data);

        if (new Date().getTime() > expiry) {
            return null;
        }

        return value;
    } catch (e) {
        return null;
    }
};
