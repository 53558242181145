import { call, put, all, takeLatest } from "redux-saga/effects";

import {
    GUILDS_REQUEST,
    GUILDS_SUCCESS,
    GUILDS_FAIL,
    LANDS_GUILDS_REQUEST,
    LANDS_GUILDS_SUCCESS,
    LANDS_GUILDS_FAIL
} from "../actions/actionTypes/guild.action-types";

import { Api } from "../../api";
import { handleError } from "../../helpers/error.helper";

function* getGuilds() {
    try {
        const { data } = yield call(Api.guild.getGuilds);

        yield put({
            type: GUILDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: GUILDS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getLandsGuilds(action) {
    try {
        const landsIDs = action.payload;

        const { data } = yield call(Api.guild.getLandsGuilds, { landsIDs });

        yield put({
            type: LANDS_GUILDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: LANDS_GUILDS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getGuildsSaga() {
    yield takeLatest(GUILDS_REQUEST, getGuilds);
}

function* getLandsGuildsSaga() {
    yield takeLatest(LANDS_GUILDS_REQUEST, getLandsGuilds);
}

export function* guildSaga() {
    yield all([
        call(getGuildsSaga),
        call(getLandsGuildsSaga),
    ])
}
