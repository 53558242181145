import { WAX, FLOW, IMX, APTOS } from "../constants/chain.constants";
import { CARD, LAND, HERO } from "../constants/type.constants";
import { FILTER_TYPE, FILTER_RARITY } from "../constants/staking.constants";
import {
    RARE_HEROES,
    EPIC_HEROES,
    LEGENDARY_HEROES,
    MYTHICAL_HEROES,
    DC_HEROES,
    CULTISTS,
    VAMPIRE_CARDS
} from "../constants/schema.constants";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter.helper";

const mapToItemsDTO = ({
    id, collection, templateID, blockchain, name, rarity, mediaUrl, ipfs, type, staked, locked, lockTimestamp, reforgeLvl, schema, status
}) => {
    return {
        id, collection, templateID, blockchain, name, rarity, mediaUrl, ipfs, type, staked, locked, lockTimestamp, reforgeLvl, schema, status
    };
};

const getItemType = ({ type, schemaName, name}) => {
    type = type?.toLowerCase();
    schemaName = schemaName?.toLowerCase();

    if (type && type === HERO)
        return FILTER_TYPE.HEROES;

    if (type && type === CARD)
        return FILTER_TYPE.CARDS;

    if (type && type === LAND)
        return FILTER_TYPE.LANDS;

    if (schemaName && (schemaName.includes(CARD.toLowerCase()) || schemaName === CULTISTS))
        return FILTER_TYPE.CARDS;

    if (schemaName && schemaName === LAND.toLowerCase())
        return FILTER_TYPE.LANDS;

    if (schemaName && schemaName.includes(HERO.toLowerCase()))
        return FILTER_TYPE.HEROES;

    if (schemaName && name && (schemaName.includes('other') && name === 'Soul Card'))
        return FILTER_TYPE.SOUL

    if (type && type !== "pack") // imx cards
        return FILTER_TYPE.CARDS;

    return '';
};

const getHeroRarityBySchema = ({ heroSchema }) => {
    switch (heroSchema.toLowerCase()) {
        case RARE_HEROES:
            return FILTER_RARITY.RARE;

        case EPIC_HEROES:
            return FILTER_RARITY.EPIC;

        case LEGENDARY_HEROES:
            return FILTER_RARITY.LEGENDARY;

        case MYTHICAL_HEROES:
            return FILTER_RARITY.MYTHICAL;

        case DC_HEROES:
            return FILTER_RARITY.DC_RARITY;

        default:
            return null;
    }
};

const getRarityByName = (name) => {
    if (!name)
        return null;

    name = name.toLowerCase();

    return [FILTER_RARITY.COMMON, FILTER_RARITY.RARE, FILTER_RARITY.EPIC, FILTER_RARITY.LEGENDARY, FILTER_RARITY.MYTHICAL]
        .find(rarity => name.includes(rarity.toLowerCase()));
};


export const mapImxItemsResponse = (items) => {
    return items.filter(({type, metadata}) =>  {
        if (!metadata || metadata.Name === null) {
            return false;
        }
        return (type !== 'pack') && (!metadata.Name?.toLowerCase().includes('pack'))
    }).map(({
        id,
        collection,
        status,
        token_id,
        metadata: { Name, Rarity, image_url, Type }
    }) => {
        if (Rarity) {
            return mapToItemsDTO({
                id,
                collection: collection.name,
                templateID: token_id,
                blockchain: IMX,
                name: Name,
                rarity: Rarity,
                mediaUrl:image_url,
                ipfs: image_url,
                type: getItemType( {type:Type} ),
                status,
                staked: true,
                locked: false,
                lockTimestamp: null,
                reforgeLvl: null,
                schema: null
            });
        }

        return null;
    })
        .filter(item => !!item);
};


export const mapWaxItemsResponse = ({ items, stakedItemsIDs, lockedItems }) => {
    return items.map(({
        asset_id,
        data,
        collection: { collection_name },
        schema: { schema_name },
        template: { template_id, immutable_data: { name, rarity, rarityy, img, reforgelvl } }
    }) => {
        let itemRarity = rarityy ? rarityy : rarity;
        if (!itemRarity) 
            itemRarity = (data && data.rarity) ? data.rarity : getHeroRarityBySchema({ heroSchema: schema_name })

        if (itemRarity || schema_name === 'other') {
            const lockInfo = lockedItems.find(({ assets }) => assets.includes(String(asset_id)));
            let isSoulCard = schema_name === "other" && name == "Soul Card" ? true :false
            return mapToItemsDTO({
                id: asset_id,
                collection: collection_name,
                templateID: template_id,
                blockchain: WAX,
                name,
                rarity: itemRarity,
                mediaUrl: img && img.startsWith('https') ? img : '',
                ipfs: img,
                type: getItemType({ schemaName: schema_name, name }),
                staked: !!stakedItemsIDs.find(stakedItemID => String(stakedItemID) === String(asset_id)),
                locked: !!lockInfo,
                lockTimestamp: lockInfo?.unlockTimestamp,
                reforgeLvl: reforgelvl,
                schema: schema_name
            });
        }
        return null;
    })
        .filter(item => !!item);
};

export const mapAptosItemsResponse = ({ items, stakedItemsIDs, lockedItems }) => {
    return items.map(({
        current_token_data: { token_properties, token_name, token_uri },
        template_id,
        uri
    }) => {
        let itemRarity = token_properties.Rarity ||  token_properties.rarity;
        if (itemRarity) {
            const lockInfo = lockedItems.find(({ assets }) => assets.includes(String(token_name)));
            const type = (token_properties.Type || token_properties.type).toLowerCase();
            return mapToItemsDTO({
                id: token_name,
                collection: 'Changelings',
                templateID: token_properties?.Template || template_id,
                blockchain: APTOS,
                name: token_properties.Name || token_properties.name,
                rarity: capitalizeFirstLetter(itemRarity),
                mediaUrl: token_uri || uri,
                ipfs: token_uri || uri,
                type: type === 'card' ? 'Cards' : (type === 'hero') ? 'Heroes' : (type === 'land') ? 'Lands' : null,
                staked: !!stakedItemsIDs.find(stakedItemID => String(stakedItemID) === String(token_name)),
                locked: !!lockInfo,
                lockTimestamp: lockInfo?.unlockTimestamp,
                reforgeLvl: null,
                schema: null
            });
        }

        return null;
    })
        .filter(item => !!item);
};

export const mapFlowItemsResponse = ({ items, stakedItems, lockedItems }) => {
    return items.map(({ item_id, collection, item_template_id, data }) => {
        const lockInfo = lockedItems.find(({ assets }) => assets.includes(+item_id));

        let itemRarity = data?.rarity;

        if (!itemRarity)
            itemRarity = getRarityByName(data?.name);

        return mapToItemsDTO({
            id: item_id,
            collection,
            templateID: item_template_id,
            blockchain: FLOW,
            name: data?.name,
            rarity: itemRarity,
            mediaUrl: data?.mediaUrl,
            ipfs: data?.ipfs,
            type: getItemType({ type: data?.type }),
            staked: !!stakedItems.find(stakedItem => String(stakedItem) === String(item_id)),
            locked: !!lockInfo,
            lockTimestamp: lockInfo?.unlockTimestamp,
            reforgeLvl: data?.reforgelvl,
            //TODO: change it
            schema: VAMPIRE_CARDS
        });
    });
};
