import {
    REMOVE_ACTIVE_QUEST,
    UPDATE_ACTIVE_QUESTS,
    SET_ACTIVE_QUESTS_FOR_USER,
    USER_QUESTS_PARTIES_SUCCESS,
    ACTIVE_QUESTS_SUCCESS,
} from "../actionTypes/quest.action-types";

export const removeActiveQuest = (questID) => {
    return {
        type: REMOVE_ACTIVE_QUEST,
        payload: questID
    }
};

export const updateActiveQuests = (quest) => {
    return {
        type: UPDATE_ACTIVE_QUESTS,
        payload: quest
    }
};

export const updateUserQuestsParties = (questsParties) => {
    return {
        type: USER_QUESTS_PARTIES_SUCCESS,
        payload: questsParties
    }
};

export const setActiveQuestsForUser = (quests) => {
    return {
        type: SET_ACTIVE_QUESTS_FOR_USER,
        payload: quests
    }
};
export const getQuestsForUser = (response) => {
    return {
        type: ACTIVE_QUESTS_SUCCESS,
        payload: response
    }
};
