export const GUILD_HOUSE_LOADING = 'GUILD_HOUSE_LOADING';

export const GUILDS_REQUEST = 'GUILDS_REQUEST';
export const GUILDS_SUCCESS = 'GUILDS_SUCCESS';
export const GUILDS_FAIL = 'GUILDS_FAIL';

export const LANDS_GUILDS_REQUEST = 'LANDS_GUILDS_REQUEST';
export const LANDS_GUILDS_SUCCESS = 'LANDS_GUILDS_SUCCESS';
export const LANDS_GUILDS_FAIL = 'LANDS_GUILDS_FAIL';
export const LANDS_GUILD_UPDATE = 'LANDS_GUILD_UPDATE';

export const UPDATE_GUILD = 'UPDATE_GUILD';
export const ADD_GUILD = 'ADD_GUILD';
export const REMOVE_GUILD = 'REMOVE_GUILD';

export const ADD_MEMBER_TO_GUILD = 'ADD_MEMBER_TO_GUILD';
export const REMOVE_MEMBER_FROM_GUILD = 'REMOVE_MEMBER_FROM_GUILD';

export const ADD_APPLICANT_TO_GUILD = 'ADD_APPLICANT_TO_GUILD';
export const REMOVE_APPLICANT_FROM_GUILD = 'REMOVE_APPLICANT_FROM_GUILD';
