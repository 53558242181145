import * as fcl from "@blocto/fcl";

import { tx } from "../utils/tx";

import {
  DARKCOUNTRY_SDM_ADDRESS,
  FUNGIBLE_TOKEN_ADDRESS,
} from "../../../constants/flow.constants";

const CODE = `
    import FungibleToken from ${FUNGIBLE_TOKEN_ADDRESS}
    import SdmToken from ${DARKCOUNTRY_SDM_ADDRESS}
    
    transaction(amount: UFix64, to: Address) {
    
        // The Vault resource that holds the tokens that are being transferred
        let sentVault: @FungibleToken.Vault
    
        prepare(signer: AuthAccount) {
    
            // Get a reference to the signer's stored vault
            let vaultRef = signer.borrow<&SdmToken.Vault>(from: /storage/sdmTokenVault)
                ?? panic("Could not borrow reference to the owner's Vault!")
    
            // Withdraw tokens from the signer's stored vault
            self.sentVault <- vaultRef.withdraw(amount: amount)
        }
    
        execute {
    
            // Get the recipient's public account object
            let recipient = getAccount(to)
    
            // Get a reference to the recipient's Receiver
            let receiverRef = recipient.getCapability(/public/sdmTokenReceiver)
                .borrow<&{FungibleToken.Receiver}>()
                ?? panic("Could not borrow receiver reference to the recipient's Vault")
    
            // Deposit the withdrawn tokens in the recipient's receiver
            receiverRef.deposit(from: <-self.sentVault)
        }
    }
`;

export async function depositSdmTx(amount) {
  return await tx({
    cadence: CODE,
    args: (arg, t) => [
      arg(Number(amount).toFixed(8), t.UFix64),
      arg(DARKCOUNTRY_SDM_ADDRESS, t.Address)
    ],
    proposer: fcl.currentUser,
    payer: fcl.currentUser,
    authorizations: [fcl.currentUser],
    limit: 1000,
  });
}