export const RARE_HEROES = 'rareheroes';
export const EPIC_HEROES = 'epicheroes';
export const LEGENDARY_HEROES = 'legheroes';
export const MYTHICAL_HEROES = 'mythheroes';
export const DC_HEROES = 'dcheroes';

export const CULTISTS = 'cultists';

export const STANDARD_CARD = 'standardcard';

export const COMMON_CARDS = 'commoncards';
export const RARE_CARDS = 'rarecards';
export const EPIC_CARDS = 'epiccards';
export const LEGEND_CARDS = 'legendcards';
export const MYTH_CARDS = 'mythcards';

export const VAMPIRE_CARDS = 'vampirecards';
