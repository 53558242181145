import axios from "axios";

import { to2, to3, toLocal } from "../../helpers/api.helper";

export const sdm = {
    stakeSdm: ({ landID, sdmBalance }) => {
        return axios.post(to2('sdm/stake'), { landID, sdmBalance });
    },

    unstakeSdm: ({ landID, sdmBalance }) => {
        return axios.post(to2('sdm/unstake'), { landID, sdmBalance });
    },

    transferSdmFromLandToUser: ({ landID, sdmBalance }) => {
        return axios.post(to2('sdm/transfer/user'), { landID, sdmBalance });
    },

    transferSdmFromUserToLand: ({ landID, sdmBalance }) => {
        return axios.post(to2('sdm/transfer/land'), { landID, sdmBalance });
    },

    depositFlowSdm: ({ transactionID }) => {
        return axios.post(to3('sdm/deposit/flow'), { transactionID });
    },

    withdrawSdm: ({ sdmBalance, blockchain }) => {
        return axios.post(to2('sdm/withdraw'), { sdmBalance, blockchain });
    },

    countSdmPayout: ({ landID }) => {
        return axios.post(to2('sdm/payout'), { landID });
    },

    updatePayoutVisibility: ({ payoutID }) => {
        return axios.put(to2('sdm/payout'), { payoutID });
    },

    markPayoutAsRead: ({ payoutIDs }) => {
        return axios.put(to2('sdm/payout/read'), { payoutIDs });
    },
    getUserSdmHistory: () => {
        return axios.get(to2("sdm/history/user"));
    }
};
