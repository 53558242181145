import axios from "axios";

import { to, toLocal } from "../../helpers/api.helper";

export const questParty = {
    getUserQuestsParties: () => {
        return axios.get(to("quest/party/user"));
    },

    createQuestParty: ({ name, hero, cards }) => {
        return axios.post(to("quest/party"), {
            name, hero, cards
        });
    },

    updateQuestParty: ({ questPartyID, name, hero, cards }) => {
        return axios.patch(to("quest/party"), {
            questPartyID, name, hero, cards
        });
    },

    deleteQuestParty: ({ questPartyID }) => {
        return axios.delete(to(`quest/party?questPartyID=${questPartyID}`));
    },
};
