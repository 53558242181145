export const COMMON_LAND = 'Common Land';
export const RARE_LAND = 'Rare Land';
export const EPIC_LAND = 'Epic Land';
export const LEGENDARY_LAND = 'Legendary Land';
export const MYTHICAL_LAND = 'Mythical Land';

export const BUILDING_1 = { id: '1', name: 'Saloon' };
export const BUILDING_2 = { id: '2', name: 'Town Hall' };
export const BUILDING_3 = { id: '3', name: 'Bank' };
export const BUILDING_4 = { id: '4', name: 'building4' };
export const BUILDING_5 = { id: '5', name: 'building5' };
export const BUILDING_6 = { id: '6', name: 'building6' };
export const BUILDING_7 = { id: '7', name: 'building7' };
export const BUILDING_8 = { id: '8', name: 'Sheriffs Office' };
// export const BUILDING_9 = { id: '9', name: 'Guild House' };
export const BUILDING_9 = { id: '9', name: 'Barracks' };
export const BUILDING_10 = { id: '10', name: 'Mercenary Outpost' };
export const BUILDING_11 = { id: '11', name: 'building11' };

export const LAND_IMAGE_MAP_WIDTH_INITIAL = '1920';
export const LAND_IMAGE_MAP_HEIGHT_INITIAL = '1080';

export const LAND_IMAGE_MAP_WIDTH_2 = '1800';
export const LAND_IMAGE_MAP_HEIGHT_2 = '1012.5';

export const LAND_IMAGE_MAP_WIDTH_3 = '1600';
export const LAND_IMAGE_MAP_HEIGHT_3 = '900';

export const LAND_IMAGE_MAP_WIDTH_4 = '1400';
export const LAND_IMAGE_MAP_HEIGHT_4 = '787.5';

export const LAND_IMAGE_MAP_WIDTH_5 = '1200';
export const LAND_IMAGE_MAP_HEIGHT_5 = '675';

export const LAND_IMAGE_MAP_WIDTH_6 = '1000';
export const LAND_IMAGE_MAP_HEIGHT_6 = '529.4';

export const LAND_IMAGE_MAP_WIDTH_7 = '800';
export const LAND_IMAGE_MAP_HEIGHT_7 = '450';

export const MAX_LANDS_QUANTITY = 4000;
