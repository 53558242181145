import {
    ACTIVE_REFORGE_CONTRACTS_REQUEST,
    ACTIVE_REFORGE_CONTRACTS_SUCCESS,
    ACTIVE_REFORGE_CONTRACTS_FAIL,
    USER_JOINED_REFORGE_CONTRACTS_REQUEST,
    USER_JOINED_REFORGE_CONTRACTS_SUCCESS,
    USER_JOINED_REFORGE_CONTRACTS_FAIL,
    REMOVE_ACTIVE_REFORGE_CONTRACT,
    REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND,
    SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER,
    ADD_ACTIVE_REFORGE_CONTRACT
} from "../actions/actionTypes/reforgeContract.action-types";
import { SIGN_OUT_SUCCESS } from "../actions/actionTypes/auth.action-types";

const initialState = {
    activeReforgeContracts: [],
    activeReforgeContractsFetched: false,

    activeReforgeContractsForUser: [],

    userJoinedReforgeContracts: [],
    userJoinedReforgeContractsFetched: false,

    loading: false,
};

export default function reforgeContract(state = initialState, action) {
    switch (action.type) {
        case ACTIVE_REFORGE_CONTRACTS_REQUEST:
        case USER_JOINED_REFORGE_CONTRACTS_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ACTIVE_REFORGE_CONTRACTS_SUCCESS:
            return {
                ...state,
                activeReforgeContracts: action.payload,
                activeReforgeContractsFetched: true,
                loading: false
            };

        case ADD_ACTIVE_REFORGE_CONTRACT: {
            return {
                ...state,
                activeReforgeContracts: [...state.activeReforgeContracts, action.payload]
            };
        }

        case ACTIVE_REFORGE_CONTRACTS_FAIL:
            return {
                ...state,
                activeReforgeContracts: [],
                activeReforgeContractsFetched: true,
                loading: false
            };

        case SET_ACTIVE_REFORGE_CONTRACTS_FOR_USER:
            return {
                ...state,
                activeReforgeContractsForUser: action.payload
            };

        case USER_JOINED_REFORGE_CONTRACTS_SUCCESS:
            return {
                ...state,
                userJoinedReforgeContracts: action.payload,
                userJoinedReforgeContractsFetched: true,
                loading: false
            };

        case USER_JOINED_REFORGE_CONTRACTS_FAIL:
            return {
                ...state,
                userJoinedReforgeContracts: [],
                userJoinedReforgeContractsFetched: true,
                loading: false
            };

        case REMOVE_ACTIVE_REFORGE_CONTRACT:
            return {
                ...state,
                activeReforgeContracts: state.activeReforgeContracts
                    .filter(({ _id }) => _id !== action.payload)
            };

        case REMOVE_ACTIVE_REFORGE_CONTRACTS_BY_LAND:
            return {
                ...state,
                activeReforgeContracts: state.activeReforgeContracts
                    .filter(({ land: { _id } }) => _id !== action.payload)
            };

        case SIGN_OUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
}
