export const AWS_SERVER_ENDPOINT = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? "https://v4lyztcav5.execute-api.us-east-1.amazonaws.com/dev-2"
    : "https://dq5niaef4c.execute-api.us-east-1.amazonaws.com/dev-2";

export const AWS_SERVER_ENDPOINT_2 = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? "https://75l9ktxjn4.execute-api.us-east-1.amazonaws.com/dev-3"
    : "https://q2dglgqnyj.execute-api.us-east-1.amazonaws.com/dev-3";

export const AWS_SERVER_ENDPOINT_3 = process.env.REACT_APP_WAX_CHAIN === 'testnet'
    ? "https://1fdzg9hv32.execute-api.us-east-1.amazonaws.com/dev-4"
    : " https://ndzvvdz1el.execute-api.us-east-1.amazonaws.com/dev-4";

export const AWS_LOCAL_SERVER_ENDPOINT = "http://localhost:3000/dev-2";

export const FLOW_MARKET_NFT_API = 'https://7l5tdhtuqa.execute-api.us-east-1.amazonaws.com/prod';
