import axios from "axios";

import { MAX_LANDS_QUANTITY } from "../../constants/lands.constants";
import { to, to2,toLocal } from "../../helpers/api.helper";

export const land = {
    getUserLands: () => {
        return axios.get(to("land/user"));
    },

    updateLandName: ({ landID, name }) => {
        return axios.put(to("land/name"), {
            landID, name
        });
    },

    upgradeBuilding: ({ landID, buildingName, newLevel }) => {
        return axios.put(to("land/building/level"), {
            landID, buildingName, newLevel
        });
    },

    getInfluenceLeaderboard: () => {
        return axios.get(to(`land/influence/leaderboard?limit=${MAX_LANDS_QUANTITY}`));
    },
    
    setupRent: ({ landID, tenantUsername, fee, timeInHours }) => {
        return axios.post(to("land/rent"), {
            landID, tenantUsername, fee, timeInHours
        });
    },

    updateLandInternalReportVisibility: ({ reportID }) => {
        return axios.put(to("land/internal-report"), { reportID });
    },

    markLandInternalReportAsRead: ({ reportIDs }) => {
        return axios.put(to("land/internal-report/read"), { reportIDs });
    },

    getLandInfoByAssetId: (assetId) => {
        return axios.get(to2(`land/info?assetId=${assetId}`));   //to2
    },
};
