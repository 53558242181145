import { call, put, all, takeLatest } from "redux-saga/effects";

import { Api } from "../../api";
import {
    USER_LANDS_REQUEST,
    USER_LANDS_SUCCESS,
    USER_LANDS_FAIL,
    INFLUENCE_LEADERBOARD_REQUEST,
    INFLUENCE_LEADERBOARD_SUCCESS,
    INFLUENCE_LEADERBOARD_FAIL,
    FACTIONS_STAKES_REQUEST,
    FACTIONS_STAKES_SUCCESS,
    FACTIONS_STAKES_FAIL
} from "../actions/actionTypes/land.action-types";
import { handleError } from "../../helpers/error.helper";

function* getUserLands() {
    try {
        const { data } = yield call(Api.land.getUserLands);

        yield put({
            type: USER_LANDS_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: USER_LANDS_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getInfluenceLeaderboard() {
    try {
        const { data } = yield call(Api.land.getInfluenceLeaderboard);

        yield put({
            type: INFLUENCE_LEADERBOARD_SUCCESS,
            payload: data
        });
    } catch (error) {
        const errorMessage = handleError({ error });

        yield put({
            type: INFLUENCE_LEADERBOARD_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getFactionsStakes() {
    try {
        const { data } = yield call(Api.faction.getFactionsStakes);

        yield put({
            type: FACTIONS_STAKES_SUCCESS,
            payload: data
        });
    } catch (error) {
         const errorMessage = handleError({ error,showToast:false});

        yield put({
            type: FACTIONS_STAKES_FAIL,
            payload: { error: errorMessage }
        });
    }
}

function* getUserLandsSaga() {
    yield takeLatest(USER_LANDS_REQUEST, getUserLands);
}

function* getInfluenceLeaderboardSaga() {
    yield takeLatest(INFLUENCE_LEADERBOARD_REQUEST, getInfluenceLeaderboard);
}

function* getFactionsStakesSaga() {
    yield takeLatest(FACTIONS_STAKES_REQUEST, getFactionsStakes);
}

export function* landsSagas() {
    yield all([
        call(getUserLandsSaga),
        call(getInfluenceLeaderboardSaga),
        call(getFactionsStakesSaga),
    ])
}
