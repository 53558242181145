export const USER_LANDS_REQUEST = 'USER_LANDS_REQUEST';
export const USER_LANDS_SUCCESS = 'USER_LANDS_SUCCESS';
export const USER_LANDS_FAIL = 'USER_LANDS_FAIL';

export const SET_ACTIVE_USER_LAND = 'SET_ACTIVE_USER_LAND';
export const SHOW_LAND_OWNER_INFO = 'SHOW_LAND_OWNER_INFO';
export const UPDATE_USER_LANDS = 'UPDATE_USER_LANDS';
export const UPDATE_LAND_NAME = 'UPDATE_LAND_NAME';
export const UPDATE_LAND = "UPDATE_LAND";

export const REMOVE_GUILD_WITH_APPLICANT_FROM_USER_LAND = 'REMOVE_GUILD_WITH_APPLICANT_FROM_USER_LAND';

export const INFLUENCE_LEADERBOARD_REQUEST = 'INFLUENCE_LEADERBOARD_REQUEST';
export const INFLUENCE_LEADERBOARD_SUCCESS = 'INFLUENCE_LEADERBOARD_SUCCESS';
export const INFLUENCE_LEADERBOARD_FAIL = 'INFLUENCE_LEADERBOARD_FAIL';

export const FACTIONS_STAKES_REQUEST = 'FACTIONS_STAKES_REQUEST';
export const FACTIONS_STAKES_SUCCESS = 'FACTIONS_STAKES_SUCCESS';
export const FACTIONS_STAKES_FAIL = 'FACTIONS_STAKES_FAIL';

export const MAKE_MESSAGES_READ = 'MAKE_MESSAGES_READ';
export const DELETE_MESSAGE = 'DELETE_MESSAGES';

export const STAKE_SDM_TO_FACTION = 'STAKE_SDM_TO_FACTION';
export const UNSTAKE_SDM_FROM_FACTION = 'UNSTAKE_SDM_FROM_FACTION';
