import ecc from "eosjs-ecc";
import { SERVER_PUBLIC_KEY } from "../config";

const base64 = {
    decode: s => Uint8Array.from(atob(s), c => c.charCodeAt(0)),
    encode: b => btoa(String.fromCharCode(...new Uint8Array(b)))
};

function initKeys(key) {
    const privateEncryptionKey = ecc.PrivateKey.fromSeed(key);
    const privateKey = privateEncryptionKey.toString();

    const publicEncryptionKey = privateEncryptionKey.toPublic();
    const publicKey = publicEncryptionKey.toString().replace('EOS', '');

    return { privateKey, publicKey };
}

export function encrypt(data) {

    const keys = initKeys(data);
    const encryptStr = ecc.Aes.encrypt(keys.privateKey, SERVER_PUBLIC_KEY, data.toString());

    console.log(encryptStr);
    return [
        base64.encode(encryptStr.message),
        encryptStr.nonce.low,
        encryptStr.nonce.high,
        encryptStr.checksum,
        keys.publicKey
    ]
        .join(',');
}
