import {
    LOCAL_STORAGE_ACCESS_TOKEN,
    LOCAL_STORAGE_REFRESH_TOKEN
} from "../constants/localStorage.constants";
import {
    setToLocalStorageWithExpiry
} from "./storage.helper";

export const setAuthTokensToStorage = ({ accessToken, refreshToken }) => {
    setToLocalStorageWithExpiry({
        key: LOCAL_STORAGE_ACCESS_TOKEN,
        value: accessToken,
        //20m
        ttl: 1200 * 1000
    });

    setToLocalStorageWithExpiry({
        key: LOCAL_STORAGE_REFRESH_TOKEN,
        value: refreshToken,
        //3h
        ttl: 10800 * 1000
    });
};
