import axios from "axios";

import { to2,toLocal } from "../../helpers/api.helper";

export const soldiers = {
    createRecruit: ({ landID, rank, salary }) => {
        return axios.post(to2('soldiers/recruit'), { landID, rank, salary });
    },

    deleteRecruit: ({ landID, recruitID }) => {
        return axios.delete(to2(`soldiers/recruit?landID=${landID}&recruitID=${recruitID}`));
    },

    approveSoldierApplicant: ({ landID, applicantUserID }) => {
        return axios.patch(to2('soldiers/applicant/approve'), { landID, applicantUserID });
    },

    rejectSoldierApplicant: ({ landID, applicantUserID }) => {
        return axios.patch(to2('soldiers/applicant/reject'), { landID, applicantUserID });
    },

    dismissSoldier: ({ landID, soldierID }) => {
        return axios.delete(to2(`soldiers?landID=${landID}&soldierID=${soldierID}`));
    },
};
