import axios from "axios";

import { to2,toLocal } from "../../helpers/api.helper";

export const faction = {
    getFactionsStakes: () => {
        return axios.get(to2("faction/stakes"));
    },

    chooseFaction: ({ landID, factionName }) => {
        return axios.post(to2("faction"), { landID, factionName });
    },

    stakeSdmToFaction: ({ factionName, blockchain, sdmBalance }) => {
        return axios.post(to2("faction/sdm/stake"), { factionName, blockchain, sdmBalance });
    },

    unstakeSdmFromFaction: ({ factionName, blockchain, sdmBalance }) => {
        return axios.post(to2("faction/sdm/unstake"), { factionName, blockchain, sdmBalance });
    },
};
