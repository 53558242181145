import axios from "axios";

import { to, to3 } from "../../helpers/api.helper";

export const auth = {
    emailSignIn: ({ email, password }) => {
        return axios.post(to("auth/sign-in/email"), {
            email, password
        });
    },

    googleSingIn: ({ email, googleID, googleName, token }) => {
        return axios.post(to("auth/sign-in/google"), {
            email, googleID, googleName, token
        });
    },

    waxSingIn: ({ waxAccount, signature, wallet }) => {
        return axios.post(to("auth/sign-in/wax"), {
            waxAccount, signature, wallet
        });
    },

    waxSingUp: ({ waxAccount, email, password }) => {
        return axios.post(to("auth/sign-up/wax"), {
            waxAccount, email, password
        });
    },

    flowSingIn: ({ flowAccount, signature }) => {
        return axios.post(to("auth/sign-in/flow"), {
            flowAccount, signature
        });
    },

    flowSingUp: ({ flowAccount, email, password }) => {
        return axios.post(to("auth/sign-up/flow"), {
            flowAccount, email, password
        });
    },

    aptosSingIn: ({ aptosAccount, signature, message, publicKey}) => {
        return axios.post(to3("auth/sign-in/aptos"), {
            aptosAccount, signature, message, publicKey
        });
    },

    aptosSingUp: ({ aptosAccount, email, password }) => {
        return axios.post(to3("auth/sign-up/aptos"), {
            aptosAccount, email, password 
        });
    },

    twoFactorAuthentication: ({ userID, code }) => {
        return axios.post(to("auth/sign-in/two-factor"), {
            userID, code
        });
    },

    emailSignUp: ({ email, password }) => {
        return axios.post(to("auth/sign-up/email"), {
            email, password
        });
    },

    refreshToken: ({ userID, refreshToken }) => {
        return axios.post(to("auth/refresh"), {
            userID, refreshToken
        });
    },

    signOut: () => {
        return axios.post(to("auth/sign-out"));
    }
};
