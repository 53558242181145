import axios from "axios";

import { to2, toLocal } from "../../helpers/api.helper";

export const landGuildWars = {
    setupAttack: ({ attackerGuildID,defenderGuildID, prizePool,
        timerDelay = 3600 }) => {
        return axios.post(to2("land-guild-wars/attack"), { attackerGuildID, defenderGuildID, prizePool, timerDelay });
    },
    getWars: ({landId, type}) => {
        return axios.get(to2("land-guild-wars/get"+`?landId=${landId}&type=${type}`));
    },
    kickPlayer: ({ warId, userId }) => {
        return axios.get(to2("land-guild-wars/kick" + `?id=${warId}&userID=${userId}`));
    },
};
