import axios from "axios";

import { toast } from "react-toastify";

import { SDM } from "../constants/token.constants";
import { PACKS_TEMPLATES } from "../constants/flow.constants";
import { FLOW_MARKET_NFT_API } from "../constants/enpoints.constants";
import { readCollectionIds, readUserFlowSdmBalance } from "../blockchains/flow";
import { mapFlowItemsResponse } from "../helpers/response.helper";

export const fetchUserFlowItems = async ({ address, stakedItems, lockedItems }) => {
    try {
        const assetIds = await readCollectionIds({ address });

        if (!assetIds || !assetIds.length)
            return [];

        const { data } = await axios.get(`${FLOW_MARKET_NFT_API}/user-nfts?itemIds=${assetIds.join(',')}`);

        return mapFlowItemsResponse({
            items: data.filter(({ item_template_id }) => !PACKS_TEMPLATES.includes(+item_template_id)),
            stakedItems,
            lockedItems
        });
    } catch (e) {
        if (!e.message.includes('Could not borrow capability from public collection'))
            toast.error(e.message);

        return [];
    }
};

export const getWalletFlowSdmBalance = async ({ account }) => {
    try {
        const balance = await readUserFlowSdmBalance({ address: account });
        return `${Number(balance).toFixed(2)} ${SDM}`;
    } catch (e) {
        if (!e.message.includes('Could not borrow Balance capability'))
            toast.error(e.message);

        return `0 ${SDM}`;
    }
};
