export const EMAIL_SIGN_IN_REQUEST = "EMAIL_SIGN_IN_REQUEST";
export const EMAIL_SIGN_IN_SUCCESS = "EMAIL_SIGN_IN_SUCCESS";
export const EMAIL_SIGN_IN_FAIL = "EMAIL_SIGN_IN_FAIL";

export const GOOGLE_SIGN_IN_REQUEST = "GOOGLE_SIGN_IN_REQUEST";
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS";
export const GOOGLE_SIGN_IN_FAIL = "GOOGLE_SIGN_IN_FAIL";

export const WAX_SIGN_IN_REQUEST = "WAX_SIGN_IN_REQUEST";
export const WAX_SIGN_IN_SUCCESS = "WAX_SIGN_IN_SUCCESS";
export const WAX_SIGN_IN_FAIL = "WAX_SIGN_IN_FAIL";

export const FLOW_SIGN_IN_REQUEST = "FLOW_SIGN_IN_REQUEST";
export const FLOW_SIGN_IN_SUCCESS = "FLOW_SIGN_IN_SUCCESS";
export const FLOW_SIGN_IN_FAIL = "FLOW_SIGN_IN_FAIL";

export const APTOS_SIGN_IN_REQUEST = "APTOS_SIGN_IN_REQUEST";
export const APTOS_SIGN_IN_SUCCESS = "APTOS_SIGN_IN_SUCCESS";
export const APTOS_SIGN_IN_FAIL = "APTOS_SIGN_IN_FAIL";

export const EMAIL_SIGN_UP_REQUEST = "EMAIL_SIGN_UP_REQUEST";
export const EMAIL_SIGN_UP_SUCCESS = "EMAIL_SIGN_UP_SUCCESS";
export const EMAIL_SIGN_UP_FAIL = "EMAIL_SIGN_UP_FAIL";

export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAIL = "SIGN_OUT_FAIL";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const TWO_FACTOR_AUTHENTICATION_REQUEST = "TWO_FACTOR_AUTHENTICATION_REQUEST";
export const TWO_FACTOR_AUTHENTICATION_SUCCESS = "TWO_FACTOR_AUTHENTICATION_SUCCESS";
export const TWO_FACTOR_AUTHENTICATION_FAIL = "TWO_FACTOR_AUTHENTICATION_FAIL";
