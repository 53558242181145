import axios from "axios";

import { to2, toLocal } from "../../helpers/api.helper";

export const leasingCard = {
    addCardsToLeasing: ({ cardsAssetIds, access, landID,sdmValue }) => {
        
        return axios.post(to2("card/leasing"), {
            cardsAssetIds, access, landID,sdmValue
        });
    },

    removeCardsFromLeasing: ({ cardsAssetIds, landID }) => {
        return axios.patch(to2("card/leasing"), {
            cardsAssetIds, landID
        });
    },
    leaseCards: ({ cardsAssetIds, landID, userID }) => {
        return axios.put(to2("card/leasing"), {
            cardsAssetIds, landID, userID
        });
    },

    
    //get to leasing
    getCardForLeasing: () => {

        return axios.get(to2("card/leasing-site"));
    },

    leaseCardsSite: ({ cards, blockchain}) => {
        return axios.put(to2("card/leasing-site"), {
            cards, blockchain
        });
    },

};
